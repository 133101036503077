export const add_affiliate_form_keys = {
  full_name: {
    key: "full_name",
    label: "Name",
    placeholder: "Enter Affiliate Name",
    max_length: 50,
  },
  email: {
    key: "email",
    label: "Email",
    placeholder: "Enter Affiliate Email ID",
  },
  phone_number: {
    key: "phone_number",
    country_code_key: "country_code",
    country_name_key: "country",
    label: " Mobile Number",
    placeholder: "Enter phone number",
  },
};
