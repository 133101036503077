import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { FETCH_COMMISSION_STATS } from "features/AffiliateMarketing/constants/Affliates.api";

export const getAffliateCommissionStats = async (filters) => {
  return dataProvider.custom_request(
    FETCH_COMMISSION_STATS,
    apiMethods.GET,
    filters
  );
};
