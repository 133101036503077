import { dataProvider } from "data";
import { EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS } from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch, useSelector } from "react-redux";
import { apiMethods } from "data/api.constants";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";
import { useState } from "react";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useAffiliateSettingsButton } from "../../../utils/useAffiliateSettingsButton";
import { AFFILIATE_SETTINGS_TABS } from "../../../constants/AffiliateSettings.constants";
import { useGetCommissionPayload } from "features/AffiliateMarketing/modules/AffiliateCommissionForm/utils/useGetCommissionPayload";
import { UPDATE_AFFILIATE_SETTINGS_API } from "features/AffiliateMarketing/constants/Affliates.api";

export const useAffiliateSettingsModal = () => {
  const affiliateSettings = useSelector(
    (state) => state.affiliateMarketing.affiliateSettings
  );
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const { closeAffiliateSettingsModal } = useAffiliateSettingsButton();
  const { getCommissionPayload } = useGetCommissionPayload();

  const [processing, setProcessing] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    AFFILIATE_SETTINGS_TABS.affiliate_program_setup.value
  );

  const formValues = affiliateSettings.formValues;

  const updateAffiliateSettings = async (values) => {
    try {
      if (processing) return;
      setProcessing(true);

      const updatedValues = await getCommissionPayload({
        currentValues: values,
        initialValues: formValues,
      });

      const payload = { updated_values: updatedValues };

      const response = await dataProvider.custom_request(
        UPDATE_AFFILIATE_SETTINGS_API,
        apiMethods.POST,
        payload
      );

      if (isRequestSuccessful(response?.status)) {
        notify(
          "Affiliate Settings updated successfully",
          notification_color_keys.success
        );
        closeAffiliateSettingsModal();
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_AFFILIATE_SETTINGS,
          payload: values,
        });
      }
    } catch (error) {
      logError({
        error,
        when: "updateAffiliateSettings",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffiliateManagementHeader/modules/AffiliateSettings/utils/useAffiliateSettings.js",
      });
    } finally {
      setProcessing(false);
    }
  };

  return {
    updateAffiliateSettings,
    formValues,
    processing,
    selectedTab,
    setSelectedTab,
  };
};
