import React from "react";
import { Dropdown, DatePicker, Box } from "@my-scoot/component-library-legacy";
import { useAffliatesTransactionStats } from "./utils/useAffliatesTransactionStats";
import { date_range_select_options } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import moduleStyle from "./AffliatesTransactionStats.module.css";
import { convertStringToDateObject } from "features/Common/modules/DateTime/utils/DateTime.utils";
import { STATS_KEYS } from "./constants/AffliatesTransactionStats.constants";
import StatsCard from "common/Components/StatsCard";
import { format } from "date-fns";
import { YEAR_MONTH_DAY_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";

export const AffliatesTransactionStats = () => {
  const {
    selectedRange,
    onRangeChange,
    getFormattedDateRange,
    dateRange,
    setDateRange,
    statsData,
  } = useAffliatesTransactionStats();
  const isDesktop = useDesktopMediaQuery();

  const customSelected =
    selectedRange === date_range_select_options.custom.value;

  return (
    <Box className={`${moduleStyle.statsContainer} h-100 p-0 p-md-3`}>
      <div
        className={`${moduleStyle.selectContainer} ${
          customSelected ? "align-items-start" : "align-items-center"
        }`}
      >
        <Dropdown
          options={Object.values(date_range_select_options)}
          value={selectedRange}
          handleChange={onRangeChange}
          placeholder="Select date range"
          className={`mr-md-4 ${moduleStyle.select}`}
        />
        {!customSelected ? (
          <p className={moduleStyle.dates}>{getFormattedDateRange}</p>
        ) : (
          <div
            className={`d-flex align-items-center justify-content-center ${moduleStyle.datePickerContainer} mr-2 mr-md-0`}
          >
            <DatePicker
              size={!isDesktop ? "thin" : "small"}
              calendarWrapper={moduleStyle.datePickerCalendar}
              placeholder="From date"
              value={
                dateRange?.startDate
                  ? convertStringToDateObject({
                      dateString: dateRange?.startDate,
                    })
                  : null
              }
              onChange={(date) =>
                setDateRange({
                  ...dateRange,
                  startDate: format(date, YEAR_MONTH_DAY_FORMAT),
                })
              }
              wrapperClassName={`w-50 mr-2 ${moduleStyle.datePicker}`}
            />
            <DatePicker
              popperPlacement={isDesktop ? "bottom-end" : ""}
              size={!isDesktop ? "thin" : "small"}
              wrapperClassName={`w-50 ml-2 ${moduleStyle.datePicker} ${
                !dateRange?.startDate ? moduleStyle.pe_none : ""
              }`}
              calendarWrapper={moduleStyle.datePickerCalendar}
              minDate={
                dateRange?.startDate
                  ? convertStringToDateObject({
                      dateString: dateRange?.startDate,
                    })
                  : null
              }
              disabled={!dateRange?.startDate}
              placeholder="To date"
              value={
                dateRange?.endDate
                  ? convertStringToDateObject({
                      dateString: dateRange?.endDate,
                    })
                  : null
              }
              onChange={(date) =>
                setDateRange({
                  ...dateRange,
                  endDate: format(date, YEAR_MONTH_DAY_FORMAT),
                })
              }
            />
          </div>
        )}
      </div>
      <div
        className={`${moduleStyle.statsCardContainer} row align-items-center justify-content-between`}
      >
        {Object.values(STATS_KEYS).map((item) => (
          <div key={item.key} className={`col-4 ${moduleStyle.statCard}`}>
            <StatsCard
              {...item}
              key={item.key}
              statsNumber={statsData[item.key]}
              Icon={item.icon}
              title={item.title}
              tooltip={item.tooltipText}
              showLinkText={false}
              classes={{
                ...item.classes,
                statsNumberClass: moduleStyle.statsNumberClass,
                statsTitleClass: moduleStyle.statsTitleClass,
              }}
            />
          </div>
        ))}
      </div>
    </Box>
  );
};
