import React from "react";
import { is_empty } from "utils/validations";
import moduleStyle from "../../AffliateManagement.module.css";
import { form_data } from "features/AffiliateMarketing/constants/Affliates.constants";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";

export const DiscountCodeField = ({ record }) => {
  if (is_empty(record.no_of_discount_codes)) return "-";
  return (
    <span
      className={moduleStyle.name_field}
      onClick={() => {
        navigateTo(AFFILIATE_MARKETING_URL_KEYS.discount_codes, {
          args: record.uuid,
        });
      }}
    >
      View Discount Codes ({record[form_data.no_of_discount_codes.key] ?? 0})
    </span>
  );
};
