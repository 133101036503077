import React from "react";
import { useAffiliateSettingsButton } from "../AffiliateSettings/utils/useAffiliateSettingsButton";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

export const AffiliateManagementMobileHeader = () => {
  const { openAffiliateSettingsModal } = useAffiliateSettingsButton();
  const isDesktop = useDesktopMediaQuery();

  if (isDesktop) return null;

  return (
    <div className={`d-flex align-items-center justify-content-between`}>
      <span>Affiliate Management</span>
      <ExlyImage
        path="assets/images/affiliates/settings.svg"
        height={20}
        width={20}
        id="tag"
        alt="affliate_settings"
        onClick={openAffiliateSettingsModal}
      />
    </div>
  );
};
