import React from "react";
import style from "./AffliatePortal.module.css";
import { ExlyInput } from "common/form";
import { useNotifications } from "utils/hooks";
import { AFFLIATE_URL } from "features/AffiliateMarketing/constants/Affliates.constants";
import { copyLink } from "features/AffiliateMarketing/utils/copyLink";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

const AffliatePortalCard = ({ subContent }) => {
  const { notify } = useNotifications();
  const { previewUrl } = getCreatorHostsiteURL();
  const displayUrl = `${previewUrl}${AFFLIATE_URL}`;
  const url = `https://${displayUrl}`;
  return (
    <div className={`${style.wrapper} row no-gutters`}>
      <div className={`${style.imgWrap} col-4`}>
        <ExlyImage
          path="assets/images/affiliates/affliate_portal.svg"
          alt={"affliatePortallink"}
          className={style.imgWrapper}
        />
      </div>
      <div className={`col-8 px-2 px-md-3 ${style.contentWrap}`}>
        <div className={style.contentTitle}>Affiliate Portal Link</div>
        {subContent && <div>{subContent}</div>}
        <div className={style.contentDescription}>
          Share this link with your affiliates so that they can sign up / login
          to your affiliate portal
        </div>
        <div className={style.copyIcon}>
          <ExlyInput
            wrapperClassName={style.inputContainer}
            className={style.inputWrapper}
            value={displayUrl}
            disabled={true}
            inputElementClassName={style.disabled}
          />
          <div
            onClick={() => {
              copyLink(url, notify);
            }}
            className={`pointer ${style.iconWrapper}`}
          >
            <ExlyImage
              path="assets/images/affiliates/content_copy.svg"
              alt="Copy link"
              className={style.iconStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffliatePortalCard;
