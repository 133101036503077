import React from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyTable from "common/Components/ExlyTable";
import moduleStyle from "./AffliateTransactions.module.css";
import { affliateDetailChipsFormatter } from "features/AffiliateMarketing/modules/AffliateDetail/constants/detailColumn.config";
import {
  affiliateTransactionfilterConfig,
  allTransactionsColumnConfig,
  transaction_tab_config,
} from "./constants/transactionsColumn.config";
import EmptyImg from "../../../../../../assets/images/salesAndMarketing/discountCodes.svg";
import { AFFLIATE_ENTITY_TYPE } from "features/AffiliateMarketing/constants/Affliates.constants";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { useListController } from "react-admin";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { RESOURCE_KEYS } from "utils/OrgPermissions";
import { affiliates_transactions_tab } from "./constants/AllTransactions.constants";
import { PayoutComissionButton } from "./modules/PayoutComissionButton/PayoutComissionButton";
import { PayoutStatusModal } from "features/AffiliateMarketing/modules/PayoutStatusModal/PayoutStatusModal";
import useInvoiceViewer from "features/AffiliateMarketing/utils/useInvoiceViewer";
import { AffiliateTransactionHeader } from "features/AffiliateMarketing/modules/AffiliateTransactionHeader/AffiliateTransactionHeader";
import { affiliate_management_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";

export const AffliateTransactions = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const { getInvoiceURL } = useInvoiceViewer();
  const is_desktop = useDesktopMediaQuery();
  const {
    getTableActions,
    handleRowsSelected,
    selectedRecords,
    selectAll,
    currentPageFullSelection,
    handleSelectAll,
  } = useBulkActions(props);

  const { selectedIds, total } = useListController({
    ...props,
    perPage: DEFAULT_PER_PAGE,
  });

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.AFFILIATE.AFFLIATE_TRANSACTIONS
  );
  const emptyStateProps = {
    empty_list_icon: EmptyImg,
    alt: "No transactions yet",
    content_title: "No transactions yet",
  };
  const footerProps = {
    customFooter: (
      <div className={`${is_desktop ? "" : "w-100"} mb-1`}>
        <PayoutComissionButton />
      </div>
    ),
  };

  return (
    <>
      <AffiliateTransactionHeader />
      <div className={`px-3 px-md-4`}>
        <ExlyTable
          allValuesSelected={selectAll}
          entityName={AFFLIATE_ENTITY_TYPE.transactions.label}
          ra_props={{
            ...props,
          }}
          tableTitle={
            !is_desktop ? (
              <div className={moduleStyle.headerButtonWrapper}>
                <span>Affiliate Transactions</span>
              </div>
            ) : (
              ""
            )
          }
          bulkActionComponent={
            <SelectionCard
              actions={bulkActions}
              selectedRecords={Object.keys(selectedRecords)}
              totalRecords={total}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              currentPageFullSelection={currentPageFullSelection}
              entityName={"transaction"}
            />
          }
          {...emptyStateProps}
          noExportButton={true}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          tableFilters={affiliateTransactionfilterConfig}
          tableChipsFormatter={affliateDetailChipsFormatter()}
          customPrimaryAction={{
            label: "More Action",
            onClick: (
              row,
              rows,
              setDrawerConfig,
              setActionsDrawerConfig,
              rowIndex
            ) =>
              setDrawerConfig({
                drawerActionType: "view",
                open: true,
                row,
                rowIndex,
              }),
          }}
          selectedTab={affiliates_transactions_tab.all_transactions.value}
          columnConfig={allTransactionsColumnConfig(getInvoiceURL)}
          borderedFields={true}
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          drawerFieldsBordered
          recordFooterVariant="secondary"
          tabConfig={transaction_tab_config}
          checkboxSelection
          onRowSelected={handleRowsSelected}
          showSelectAllcheckBox={true}
          selected={selectedIds}
          primaryKey="id"
          footerProps={footerProps}
          hasMobileFooter={true}
          {...affiliate_management_back_action}
        />

        <PayoutStatusModal />
      </div>
    </>
  );
});
