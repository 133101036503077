import BulkActionDropdown from "features/AffiliateMarketing/modules/BulkActionDropdown";
import React from "react";
import { usePayoutCommissionButton } from "./utils/usePayoutCommissionButton";

export const PayoutComissionButton = () => {
  const { dropdown_options } = usePayoutCommissionButton();

  return (
    <BulkActionDropdown
      actionItem={{ text: "Payout Commissions", options: dropdown_options }}
    />
  );
};
