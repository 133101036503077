import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import {
  DELETE_DISCOUNT_CODE_API,
  FETCH_SPECIFIC_OFFERINGS_LIST,
} from "features/AffiliateMarketing/constants/Affliates.api";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { useState, useEffect } from "react";
import { logError } from "utils/error";
import { useShowDiscountCodesModal } from "./useShowDiscountCodesModal";
import { useRefresh } from "react-admin";
import { isRequestSuccessful } from "utils/Utils";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { is_empty } from "features/Common/utils/common.utils";

export const useAffiliateDiscount = () => {
  const refetch = useRefresh();
  const { notify } = useNotifications();
  const { affiliateUuid } = useGetAffiliateUuid();
  const { selectedRecord, closeDiscountCodesDeleteModal } =
    useShowDiscountCodesModal();

  const [offeringsOptions, setOfferingsOptions] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchOfferings = async () => {
      setLoading(true);
      try {
        const response = await dataProvider.custom_request(
          FETCH_SPECIFIC_OFFERINGS_LIST,
          apiMethods.GET,
          { affiliate_uuid: affiliateUuid }
        );
        if (isRequestSuccessful(response?.status) && !is_empty(response.data)) {
          const listingData = response?.data?.affiliate_assigned_listing;
          setOfferingsOptions(
            listingData?.map((listing) => ({
              ...listing,
              label: listing.title,
              value: listing.uuid,
            }))
          );
        }
      } catch (error) {
        logError({
          error,
          occuredAt:
            "src/features/modules/AddAfliate/utils/useCreateAffliates.js",
          when: "calling useAffiliateDiscount",
        });
        setOfferingsOptions([]);
      }
      setLoading(false);
    };

    fetchOfferings();
  }, []);

  const deleteDiscountCode = async () => {
    try {
      let response = await dataProvider.custom_request(
        DELETE_DISCOUNT_CODE_API,
        apiMethods.POST,
        {
          discount_uuid: selectedRecord?.uuid,
        }
      );
      if (isRequestSuccessful(response?.status)) {
        refetch();
        closeDiscountCodesDeleteModal();
        notify(response?.message, notification_color_keys.success);
      }
    } catch (error) {
      logError({
        error,
        when: "deleteDiscountCode",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateDiscount/utils/deleteDiscountCode.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    }
  };

  return { offeringsOptions, loading, deleteDiscountCode };
};
