import ExlyTable from "common/Components/ExlyTable";
import React from "react";
// import ExlyLoader from "ui/modules/ExlyLoader";
import { REVIEW_SELECTION_LIST_RA_PROPS } from "./constants/ReviewSelectionList.constants";
import { getColumnConfig } from "./constants/ReviewSelectionList.tableConfig";

export const ReviewSelectionList = () => {
  return (
    <ExlyTable
      noExportButton
      ra_props={{
        ...REVIEW_SELECTION_LIST_RA_PROPS,
      }}
      columnConfig={getColumnConfig}
      layoutProps={{
        showFixedBars: true,
        noShadow: true,
        paddingBottom: "32px",
        paddingMobile: "0px",
        noMobileBoxShadow: true,
      }}
      fieldsLeftPadded
      borderedFields
      drawerFieldsBordered
      fieldsAlignment="space-between"
      drawerFieldsAlignment="space-between"
      recordFooterVariant="secondary"
      primaryKey="uuid"
      uniqueRowIdFieldName="uuid" // mobile primary key
    />
  );
};
