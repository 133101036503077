import React from "react";
import NotificationsActive from "@material-ui/icons/NotificationsActive";
import Warning from "@material-ui/icons/Warning";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import moduleStyle from "./ActionField.module.css";
import { useBankDetail } from "features/AffiliateMarketing/utils/useBankDetail";
import { usePayment } from "features/AffiliateMarketing/utils/usePayment";

export const ActionField = ({ record }) => {
  const { handleSendRemainder } = useBankDetail();
  const { getTransactionInvoiceUrl } = usePayment();
  if (record.is_bank_account_connected) {
    return (
      <div className={`d-flex justify-content-between align-items-center`}>
        <div
          onClick={() =>
            getTransactionInvoiceUrl({
              affiliateUuid: record?.affiliate_uuid,
              payoutAmount: record?.affiliate_payout_amount,
            })
          }
          className={`pointer ${moduleStyle.text}`}
        >
          <VisibilityOutlinedIcon className={`${moduleStyle.icon}`} /> View
          Invoice
        </div>
      </div>
    );
  }

  return (
    <div className={`d-flex align-items-center`}>
      <div
        className={`${moduleStyle.notification_text} mr-2 d-flex align-items-center`}
      >
        <Warning className={`${moduleStyle.icon} mr-1`} />
        <span>Bank details not added</span>
      </div>
      <div
        onClick={() => handleSendRemainder(record?.affiliate_uuid)}
        className={`${moduleStyle.alert_text} d-flex align-items-center pointer`}
      >
        <NotificationsActive className={`${moduleStyle.icon} mr-1`} />
        <u>Remind</u>
      </div>
    </div>
  );
};
