import { useState } from "react";
import { INITIAL_STAT_VALUE } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import { dataProvider } from "data";
import { FETCH_AFFLIATES_STATS } from "features/AffiliateMarketing/constants/Affliates.api";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful, roundOff } from "utils/Utils";
import { logError } from "utils/error";
import { useDateRangeSelector } from "features/AffiliateMarketing/utils/useDateRangeSelector";

export const useAffliateManagementStats = ({ affiliateUuid }) => {
  const [statsData, setStatsData] = useState({
    total_bookings: INITIAL_STAT_VALUE,
    total_clicks: INITIAL_STAT_VALUE,
    total_sales_revenue: INITIAL_STAT_VALUE,
  });
  const [processing, setProcessing] = useState(false);

  const fetchStatsData = async ({ params }) => {
    if (processing) return;
    setProcessing(true);
    try {
      const response = await dataProvider.custom_request(
        FETCH_AFFLIATES_STATS,
        apiMethods.GET,
        { ...params, affiliate_uuid: affiliateUuid }
      );

      if (isRequestSuccessful(response.status)) {
        if (isRequestSuccessful(response.status)) {
          const responseData = response.data;
          let data = { ...statsData };
          for (let key in data) {
            if (responseData[key]) {
              data[key] = roundOff(responseData[key], 2);
            } else {
              data[key] = 0;
            }
          }
          setStatsData(data);
        }
      }
    } catch (error) {
      logError({
        error,
        when: "fetchStatsData",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateManagementStats/utils/useAffliateManagementStats.js",
      });
    } finally {
      setProcessing(false);
    }
  };

  const {
    dateRange,
    getFormattedDateRange,
    onRangeChange,
    selectedRange,
    setDateRange,
  } = useDateRangeSelector({ fetchStatsData });

  return {
    selectedRange,
    onRangeChange,
    statsData,
    getFormattedDateRange,
    dateRange,
    setDateRange,
  };
};
