import AccountBalance from "@material-ui/icons/AccountBalance";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import { ReactComponent as PaymentIcon } from "assets/images/affiliates/payments.svg";
import moduleStyle from "../AffliatesTransactionStats.module.css";
import React from "react";
import { is_desktop } from "utils/Utils";

export const STATS_KEYS = {
  total_commission: {
    title: <span>Total{!is_desktop ? <br /> : " "}Commission</span>,
    mappingKey: "total_commission",
    icon: PaymentIcon,
    key: "total_commission",
    tooltipText: `Total amount that needs to be paid to all your affiliates as commission (Commissions with Payout Status "Rejected" aren't considered)`,
    classes: {
      iconClass: `${moduleStyle.icon}`,
    },
  },
  total_credit_commission: {
    title: <span>Credited{!is_desktop ? <br /> : " "}Commisssion</span>,
    mappingKey: "total_credit_commission",
    icon: AccountBalance,
    key: "total_credit_commission",
    tooltipText:
      "Commission that has successfully been processed for your affiliates",
    className: `${moduleStyle.credited_commission}`,
    classes: {
      iconClass: `${moduleStyle.icon} ${moduleStyle.credited_commission_text}`,
      textColorClass: `${moduleStyle.credited_commission_text}`,
    },
  },
  total_pending_commission: {
    title: <span>Pending{!is_desktop ? <br /> : " "}Commission</span>,
    mappingKey: "total_pending_commission",
    icon: AccountBalanceWallet,
    key: "total_pending_commission",
    tooltipText: "Commission that is yet to be paid to your affiliates",
    className: `${moduleStyle.total_pending_commission}`,
    classes: {
      iconClass: `${moduleStyle.icon} ${moduleStyle.total_pending_commission_text}`,
      textColorClass: `${moduleStyle.total_pending_commission_text}`,
    },
  },
};
