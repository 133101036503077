import { useForm } from "react-final-form";
import { add_affiliate_form_keys } from "../../../constants/AddAffiliate.constants";

export const useAddAffiliateForm = () => {
  const form = useForm();

  const handlePhoneNumberChange = (_, countryData, e, formatedNum) => {
    const { dialCode, name: countryName } = countryData;
    let completeDialCode = "+" + dialCode;
    form.change(add_affiliate_form_keys.phone_number.key, formatedNum);
    form.change(
      add_affiliate_form_keys.phone_number.country_code_key,
      completeDialCode
    );
    form.change(
      add_affiliate_form_keys.phone_number.country_name_key,
      countryName
    );
  };

  return { handlePhoneNumberChange };
};
