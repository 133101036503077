import { dataProvider } from "data";
import { HTTP_STATUS_SUCCESS_CODES, apiMethods } from "data/api.constants";
import { logError } from "utils/error";
import { FETCH_AFFLIATE_PROFILE } from "features/AffiliateMarketing/constants/Affliates.api";

export const getAffliateProfile = async (uuid) => {
  try {
    const url = `${FETCH_AFFLIATE_PROFILE}/${uuid}`;
    let response = await dataProvider.custom_request(url, apiMethods.GET);
    if (response.status === HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_OK) {
      const { data } = response;
      return data;
    }
  } catch (err) {
    logError({
      err,
      when: "getAffliateProfile",
      occuredAt: "src/features/AffiliateMarketing/utils/getAffliateProfile.jsx",
    });
  }
};
