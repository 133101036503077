import React, { useState, useCallback } from "react";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moduleStyle from "./PayoutActions.module.css";
import { logError } from "utils/error";
import { useDispatch } from "react-redux";
import {
  EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
  EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { Button } from "@my-scoot/component-library-legacy";
import useCommissionPayout from "./utils/usehandleCommissionPayout";
import { COMISSION_PAYOUT_OPTIONS } from "features/AffiliateMarketing/modules/AffliateDetail/constants/detail.constants";
import { usePayoutModal } from "features/AffiliateMarketing/modules/PayoutModal/utils/usePayoutModal";
import { missing_bank_details_error_code } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

export const PayoutActions = () => {
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const {
    handleShowAffiliatePayoutErrorModal,
    handleShowAffiliatePayoutModal,
  } = usePayoutModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const onOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );
  const handleCommissionPayout = useCommissionPayout();
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  const handlePayout = async (value) => {
    try {
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
        payload: true,
      });
      const { data, error } = await handleCommissionPayout({
        payoutType: value,
      });

      if (data) {
        handleShowAffiliatePayoutModal(true);
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
          payload: data,
        });
      } else if (error) {
        if (
          error?.body?.data?.error_status === missing_bank_details_error_code
        ) {
          handleShowAffiliatePayoutErrorModal(true);
          dispatch({
            type: EXLY_AFFILIATE_MARKETING__SET_PAYOUT_DATA,
            payload: error.body.data,
          });
        } else {
          notify(
            error?.body?.message ?? SOMETHING_WENT_WRONG,
            notification_color_keys.error
          );
        }
      }
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      logError({
        error: error,
        when: "handlePayout",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateDetail/modules/AffliateCommissionStats/modules/PayoutActions/PayoutActions.jsx",
      });
    } finally {
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_PROCESSING,
        payload: false,
      });
    }
  };
  return (
    <div className={`w-100 pr-2`}>
      <Button
        color="primary"
        className={moduleStyle.secondaryButton}
        buttonWrapperClassName={moduleStyle.buttonWrapperClassName}
        variant="outlined"
        fullWidth
        onClick={onOpen}
      >
        <ExlyImage
          path="assets/images/affiliates/pay_commission.svg"
          alt="Pay commission"
          className={moduleStyle.imgWrapper}
        />
        Pay&nbsp;Commission
        <ExpandMoreIcon />
      </Button>

      <Popover
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <>
          {Object.values(COMISSION_PAYOUT_OPTIONS).map((item) => (
            <MenuItem key={item.value} onClick={() => handlePayout(item.value)}>
              {item.label}
            </MenuItem>
          ))}
        </>
      </Popover>
    </div>
  );
};
