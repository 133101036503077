import React from "react";
import {
  applicability_types,
  applicability_types_keys,
} from "../../constants/affliateDiscount.constants";
import Tooltip from "ui/modules/Tooltip";
import moduleStyle from "./ApplicableOfferingsField.module.css";

export const ApplicableOfferingsField = ({ record }) => {
  const applicabilityLabel =
    applicability_types[record.applicability]?.label || "";
  const offerings = record.offerings || [];
  const toolTipContent = (
    <ul className={`${moduleStyle.ul}`}>
      {offerings.map((offering) => (
        <li key={offering}>{offering}</li>
      ))}
    </ul>
  );

  return (
    <div className={`d-flex justify-content-start`}>
      <div>{applicabilityLabel}</div>
      {record.applicability ===
        applicability_types[applicability_types_keys.specific_offerings]
          .value && (
        <div className={"ml-1"}>
          <Tooltip text={toolTipContent} />
        </div>
      )}
    </div>
  );
};
