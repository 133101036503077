import { ReactComponent as RevenueIcon } from "assets/images/affiliates/bookings.svg";
import { ReactComponent as BookingIcon } from "assets/images/affiliates/revenue.svg";
import { ReactComponent as VisitorsIcon } from "assets/images/affiliates/visitors.svg";

export const STATS_KEYS = {
  total_clicks: {
    title: "Total Visitors",
    mappingKey: "total_clicks",
    icon: VisitorsIcon,
    class: "total_visitors",
    key: "total_clicks",
    tooltipText:
      "Number of unique visitors that visited any of your affiliates' link(s)",
    detailTooltipText:
      "Number of unique visitors that visited this affiliates' link(s)",
  },
  total_bookings: {
    title: "Total Bookings",
    mappingKey: "total_bookings",
    icon: RevenueIcon,
    color: "persian_blue",
    class: "total_bookings",
    key: "total_bookings",
    tooltipText: "Number of bookings received from all your affiliates",
    detailTooltipText: "Number of bookings received from this affiliate",
  },
  total_sales_revenue: {
    title: "Total Sales Revenue",
    mappingKey: "total_sales_revenue",
    icon: BookingIcon,
    color: "fine_pine",
    class: "total_revenue",
    key: "total_sales_revenue",
    tooltipText: "Total sales revenue generated from all your affiliates",
    detailTooltipText: "Total sales generated from this affiliate",
  },
};
