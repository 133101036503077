import React, { useState } from "react";
import ExlyTable from "common/Components/ExlyTable";
import { AFFLIATE_ENTITY_TYPE } from "features/AffiliateMarketing/constants/Affliates.constants";
import AddAffliateDiscountModal from "../Discount/AffliateDiscountModal";
import { AffiliateManagementHeader } from "../AffiliateManagementHeader/AffiliateManagementHeader";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { AFFILIATE_MANAGEMENT_TABS } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import {
  emptyStateProps,
  getAffilatesColumnConfig,
  managementFilterConfig,
  managementTableChipsFormatter,
} from "./constants/AffiliateManagement.tableConfig";
import { ActionField } from "./modules/ActionField/ActionField";
import { useShowAddAffiliateModal } from "../AddAffiliate/utils/useShowAddAffiliateModal";
import { AffiliateManagementMobileHeader } from "../AffiliateManagementHeader/AffiliateManagementMobileHeader";
import { affiliate_management_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL } from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch } from "react-redux";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";
import { useShowDiscountCodesModal } from "../AffliateDiscount/utils/useShowDiscountCodesModal";
import { DiscountSuccessModal } from "../Discount/DiscountSuccessModal/DiscountSuccessModal";

export const AffliateManagement = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const dispatch = useDispatch();
  const { openAddAffiliateModal } = useShowAddAffiliateModal();
  const { showDiscountCodesModal, showSuccessModal } =
    useShowDiscountCodesModal();

  const [showActionField, setShowActionField] = useState(false);
  const [rowData, setRowData] = useState(null);

  const handleActionClick = (row) => {
    setShowActionField(true);
    setRowData(row);
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL,
      payload: row,
    });
  };

  const footerProps = {
    primarybtn: "Add Affiliate",
    primaryClick: openAddAffiliateModal,
  };

  return (
    <>
      <AffiliateManagementHeader />
      <div className={`px-3 px-md-4`}>
        <ExlyTable
          entityName={AFFLIATE_ENTITY_TYPE.managment.label}
          ra_props={{
            ...props,
          }}
          tableTitle={<AffiliateManagementMobileHeader />}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          columnConfig={getAffilatesColumnConfig()}
          borderedFields={true}
          fieldsAlignment="space-between"
          customPrimaryAction={{
            label: "Actions",
            onClick: (row) => handleActionClick(row),
          }}
          tableChipsFormatter={managementTableChipsFormatter()}
          tableFilters={managementFilterConfig}
          recordFooterVariant="secondary"
          {...emptyStateProps}
          tabConfig={Object.values(AFFILIATE_MANAGEMENT_TABS)}
          selectedTab={AFFILIATE_MANAGEMENT_TABS.all_affiliates.value}
          footerProps={footerProps}
          hasMobileFooter={true}
          noExportButton={true}
          {...affiliate_management_back_action}
        />
        {showActionField && (
          <ActionField
            showActionDrawer={showActionField}
            setShowActionDrawer={setShowActionField}
            record={rowData}
          />
        )}
        {showDiscountCodesModal && <AddAffliateDiscountModal />}

        {showSuccessModal && <DiscountSuccessModal />}
      </div>
    </>
  );
});
