import React from "react";
import { Header } from "common/Components/Header";
import moduleStyle from "./AffiliateTransactionHeader.module.css";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { PayoutComissionButton } from "../AffliateTransactions/modules/AllTransactions/modules/PayoutComissionButton/PayoutComissionButton";
import { AffliatesTransactionStats } from "../AffliatesTransactionStats/AffliatesTransactionStats";
import { useReviewSelectionModal } from "../AffliateTransactions/modules/AllTransactions/modules/ReviewSelectionModal/utils/useReviewSelectionModal";
import { ReviewSelectionModal } from "../AffliateTransactions/modules/AllTransactions/modules/ReviewSelectionModal/ReviewSelectionModal";

export const AffiliateTransactionHeader = () => {
  const { showPayoutListModal } = useReviewSelectionModal();
  return (
    <>
      <div className={`d-none d-md-block`}>
        <Header
          onBackClick={() =>
            navigateTo(AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing)
          }
          classNames={{ container: "px-4 border-0" }}
          title={
            <div className={`${moduleStyle.title}`}>Affiliate Transactions</div>
          }
          right={<PayoutComissionButton />}
        />
      </div>

      <div className={`px-3 pt-2 pt-md-0 pb-4 px-md-4`}>
        <AffliatesTransactionStats />
      </div>

      {showPayoutListModal && <ReviewSelectionModal />}
    </>
  );
};
