import React from "react";
import { Form } from "react-final-form";
import { useHandleAddAffiliate } from "../../utils/useHandleAddAffiliate";
import ExlyModal from "common/Components/ExlyModal";
import { useShowAddAffiliateModal } from "../../utils/useShowAddAffiliateModal";
import { AddAffliateForm } from "../AddAffliateForm/AddAffliateForm";
import moduleStyle from "./AddAffiliateModal.module.css";
import { is_empty } from "features/Common/utils/common.utils";
import { useSetCommissionFormInitialValues } from "features/AffiliateMarketing/utils/useSetCommissionFormInitialValues";
import { notification_color_keys, useNotifications } from "utils/hooks";

export const AddAffiliateModal = () => {
  const { notify } = useNotifications();
  const { handleAddAffiliate, getInitialFormData, processing } =
    useHandleAddAffiliate();
  const { showAddAffiliateModal, closeAddAffiliateModal } =
    useShowAddAffiliateModal();

  const initialValues = getInitialFormData();
  useSetCommissionFormInitialValues({ initialValues });

  if (is_empty(initialValues)) return null;

  return (
    <Form
      onSubmit={handleAddAffiliate}
      initialValues={initialValues}
      render={({ handleSubmit, errors }) => (
        <ExlyModal
          open={showAddAffiliateModal}
          onClose={closeAddAffiliateModal}
          title={"Add an Affiliate"}
          onPrimaryBtnClick={() => {
            if (!is_empty(errors)) {
              notify("Invalid form values", notification_color_keys.error);
            }
            handleSubmit();
          }}
          primaryBtnText={processing ? "..." : "Add Affiliate"}
          primaryBtnProps={{
            type: "submit",
            disabled: processing,
            loading: processing,
          }}
          modal_props={{
            modalPaperClassName: moduleStyle.desktopModal,
          }}
        >
          <form onSubmit={handleSubmit}>
            <AddAffliateForm />
          </form>
        </ExlyModal>
      )}
    />
  );
};
