import {
  EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
  EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch, useSelector } from "react-redux";

export const useShowAddAffiliateModal = () => {
  const dispatch = useDispatch();
  const addAffiliate = useSelector(
    (state) => state.affiliateMarketing.addAffiliate
  );

  const openAddAffiliateModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_MODAL,
      payload: true,
    });
  };

  const closeAddAffiliateModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
      payload: {},
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_MODAL,
      payload: false,
    });
  };

  const closeAddAffiliateSuccessModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL,
      payload: false,
    });
  };

  return {
    showAddAffiliateModal: addAffiliate?.showAddAffiliateModal,
    openAddAffiliateModal,
    closeAddAffiliateModal,
    showAddAffliateSuccessModal: addAffiliate?.showAddAffliateSuccessModal,
    closeAddAffiliateSuccessModal,
  };
};
