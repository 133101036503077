import React from "react";
import classes from "./ActionField.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExlyPopover from "common/Components/ExlyPopover";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ActionDrawer from "common/Components/ActionDrawer/ActionDrawer";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useAffiliateManagement } from "../../utils/useAffiliateManagement";
import { useShowDiscountCodesModal } from "features/AffiliateMarketing/modules/AffliateDiscount/utils/useShowDiscountCodesModal";
import { useDispatch } from "react-redux";
import { EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL } from "features/AffiliateMarketing/redux/Affliates.actions";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

const AnchorComponent = ({ openPopover }) => {
  const commonProps = {
    className: classes.ActionField,
  };
  return (
    <span className="pointer" onClick={openPopover}>
      <MoreHorizIcon {...commonProps} />
    </span>
  );
};

export const ActionField = ({
  showActionDrawer,
  setShowActionDrawer,
  record,
}) => {
  const dispatch = useDispatch();
  const { openDiscountCodesModal } = useShowDiscountCodesModal();
  const { navigateToAffiliateDetail } = useAffiliateManagement();

  const isDesktop = useDesktopMediaQuery();

  const PopoverComponent = () => (
    <div className={classes.MenuOptions}>
      <div
        className={classes.MenuItems}
        onClick={() => {
          dispatch({
            type: EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL,
            payload: record,
          });
          openDiscountCodesModal();
        }}
      >
        <ExlyImage
          path="assets/images/affiliates/discount.svg"
          alt="Discount"
          height={20}
          width={20}
        />
        <span>Create&nbsp;Discount&nbsp;Code</span>
      </div>
      <div
        className={classes.MenuItems}
        onClick={() => {
          navigateToAffiliateDetail({ record });
        }}
      >
        <AccountCircleIcon className={classes.actionIcon} />
        View&nbsp;Details
      </div>
    </div>
  );

  let actions = [
    {
      icon: (
        <ExlyImage
          path="assets/images/affiliates/discount.svg"
          alt="Discount"
          height={20}
          width={20}
        />
      ),
      label: "Create Discount Code",
      onClick: () => {
        openDiscountCodesModal();
      },
    },
    {
      icon: <AccountCircleIcon className={classes.actionIcon} />,
      label: "View details",
      onClick: () => {
        navigateToAffiliateDetail({ record });
      },
    },
  ];

  return (
    <div>
      {isDesktop ? (
        <ExlyPopover
          AnchorComponent={AnchorComponent}
          PopoverComponent={PopoverComponent}
          onClose={() => {}}
          forceDesktopDesign
          popoverProps={{
            classes: {
              paper: classes.paperModel,
            },
          }}
        />
      ) : (
        <>
          <ActionDrawer
            open={showActionDrawer}
            onClose={() => {
              setShowActionDrawer(false);
            }}
            actions={actions}
            classes={{ wrapper: classes.actionWrapper }}
          />
        </>
      )}
    </div>
  );
};
