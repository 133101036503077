import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { ADD_AFFLIATE_API } from "features/AffiliateMarketing/constants/Affliates.api";
import { useGetCommissionPayload } from "features/AffiliateMarketing/modules/AffiliateCommissionForm/utils/useGetCommissionPayload";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { isRequestSuccessful } from "utils/Utils";
import { useShowAddAffiliateModal } from "./useShowAddAffiliateModal";
import {
  EXLY_AFFILIATE_MARKETING__SET_ADD_AFFILIATE_FORM,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { getAddAffiliateFormInitialValues } from "../modules/AddAffliateForm/utils/AddAffiiateForm.util";
import { logError } from "utils/error";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useRefresh } from "react-admin";
import { is_empty } from "features/Common/utils/common.utils";
import { form_keys } from "../../AffiliateSettings/constants/AffiliateSettings.constants";
import { getUserCountryCode } from "utils/AuthUtil";
import { country_codes } from "features/Common/modules/Phone/constants/CountryCodes.constants";
import { removeCountryCodeFromNumber } from "features/Common/modules/Phone/utils/Phone.utils";

export const useHandleAddAffiliate = () => {
  const affiliateSettings = useSelector(
    (state) => state.affiliateMarketing.affiliateSettings
  );
  const dispatch = useDispatch();
  const refetch = useRefresh();
  const { getCommissionPayload } = useGetCommissionPayload();
  const { notify } = useNotifications();
  const { closeAddAffiliateModal } = useShowAddAffiliateModal();

  const [processing, setProcessing] = useState(false);

  const handleAddAffiliate = async (values) => {
    try {
      if (processing) return;
      setProcessing(true);

      const payload = await getCommissionPayload({
        currentValues: values,
        initialValues: getInitialFormData(),
      });
      payload.phone_number = removeCountryCodeFromNumber(values.phone_number);

      const response = await dataProvider.custom_request(
        ADD_AFFLIATE_API,
        apiMethods.POST,
        payload
      );

      if (isRequestSuccessful(response?.status)) {
        closeAddAffiliateModal();
        refetch();
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_SHOW_ADD_AFFILIATE_SUCCESS_MODAL,
          payload: true,
        });
        dispatch({
          type: EXLY_AFFILIATE_MARKETING__SET_ADD_AFFILIATE_FORM,
          payload: getAddAffiliateFormInitialValues(),
        });
      }
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
      logError({
        error,
        when: "handleAddAffiliate",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffiliateManagementHeader/modules/AddAffiliate/utils/useHandleAddAffiliate.js",
      });
    } finally {
      setProcessing(false);
    }
  };

  const getInitialFormData = () => {
    if (!is_empty(affiliateSettings.formValues)) {
      const initialValue = {
        ...affiliateSettings.formValues,
        country_code: getUserCountryCode() ?? country_codes.IN.dial_code,
        phone_number: "",
      };
      delete initialValue[form_keys.disable_auto_approval.key];

      return initialValue;
    }
    return getAddAffiliateFormInitialValues();
  };

  return {
    handleAddAffiliate,
    processing,
    getInitialFormData,
  };
};
