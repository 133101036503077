import { EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL } from "features/AffiliateMarketing/redux/Affliates.actions";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import { useDispatch, useSelector } from "react-redux";
import { removeSessionStorageItem } from "utils/getterAndSetters";

export const useReviewSelectionModal = () => {
  const dispatch = useDispatch();
  const showPayoutListModal = useSelector(
    (state) => state.affiliateMarketing.payout.showPayoutListModal
  );
  const payout = useSelector((state) => state.affiliateMarketing.payout.data);
  const processing = useSelector(
    (state) => state.affiliateMarketing.processing
  );

  const toggleShowPayoutListModal = (value) => {
    if (showPayoutListModal) {
      removeSessionStorageItem(
        SESSION_STORAGE_KEYS.AFFILIATE_PAYOUT_REQUEST_ID
      );
    }
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SHOW_PAYOUT_LIST_MODAL,
      payload: value ?? !showPayoutListModal,
    });
  };

  return { showPayoutListModal, toggleShowPayoutListModal, payout, processing };
};
