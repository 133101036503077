import React, { useEffect } from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyTable from "common/Components/ExlyTable";
import moduleStyle from "./affliateDiscount.module.css";
import { Button } from "@my-scoot/component-library-legacy";
import { AFFLIATE_ENTITY_TYPE } from "features/AffiliateMarketing/constants/Affliates.constants";
import { detail_tab_config } from "../AffliateDetail/constants/detailColumn.config";
import { affiliates_detail_tab } from "../AffliateDetail/constants/detail.constants";
import {
  getAffilatesDiscountColumnConfig,
  table_filters,
} from "./constants/affliateDiscount.config";
import AddAffliateDiscountModal from "../Discount/AffliateDiscountModal";
import EmptyImg from "../../../../assets/images/salesAndMarketing/discountCodes.svg";
import { logError } from "utils/error";
import useAffiliateProfile from "../AffliateDetail/utils/useAffliateProfile";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { useShowDiscountCodesModal } from "./utils/useShowDiscountCodesModal";
import { AffiliateDetailHeader } from "../AffiliateDetailHeader/AffiliateDetailHeader";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { useAffiliateDiscount } from "./utils/useAffiliateDiscount";
import { affiliate_detail_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { DiscountStatusField } from "./modules/DiscountStatusField/DiscountStatusField";
import { DiscountDeleteAction } from "./modules/DiscountDeleteAction/DiscountDeleteAction";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";
import { DiscountSuccessModal } from "../Discount/DiscountSuccessModal/DiscountSuccessModal";

export const AffliateDiscount = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const { affiliateUuid } = useGetAffiliateUuid();
  const {
    openDiscountCodesModal,
    showDiscountCodesDeletModal,
    showDiscountCodesModal,
    showSuccessModal,
    closeDiscountCodesDeleteModal,
  } = useShowDiscountCodesModal();
  const { deleteDiscountCode } = useAffiliateDiscount();

  const is_desktop = useDesktopMediaQuery();

  const { fetchAffiliateProfile } = useAffiliateProfile();

  const footerProps = {
    primarybtn: "Create Discount Code",
    primaryClick: () => openDiscountCodesModal(),
  };
  const emptyStateProps = {
    empty_list_icon: EmptyImg,
    alt: "No discount codes yet.",
    content_title: "No discount codes yet",
    primary_cta: {
      children: "Create Discount Code",
      onClick: () => openDiscountCodesModal(),
    },
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await fetchAffiliateProfile();
      } catch (err) {
        logError({
          err,
          occuredAt:
            "src/features/AffiliateMarketing/modules/AffliateDeatils/index.jsx",
          when: "fecthing fetchAffiliateProfile",
        });
      }
    };
    fetchProfile();
  }, []);

  return (
    <>
      <AffiliateDetailHeader />
      <div className={`px-3 px-md-4 mt-0 mt-md-4`}>
        <ExlyTable
          entityName={AFFLIATE_ENTITY_TYPE.codes.label}
          ra_props={{
            ...props,
          }}
          {...emptyStateProps}
          tableTitle={
            !is_desktop ? (
              <div className={moduleStyle.headerButtonWrapper}>
                <span>Affiliate Managment</span>{" "}
              </div>
            ) : (
              ""
            )
          }
          tableActionComponent={
            <div className={moduleStyle.tableActionButtonWrapper}>
              <Button
                onClick={() => openDiscountCodesModal()}
                size="thin"
                color="secondary"
                buttonWrapperClassName={moduleStyle.btnDiscount}
                className={moduleStyle.btn}
              >
                Create Discount Codes
              </Button>
            </div>
          }
          noExportButton={true}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          renderSecondaryAction={(record) => (
            <DiscountDeleteAction record={record} />
          )}
          customPrimaryAction={{
            renderCustomAction: (record) => (
              <div className={`mr-2`}>
                <DiscountStatusField record={record} />
              </div>
            ),
          }}
          selectedTab={affiliates_detail_tab.discount_codes.value}
          columnConfig={getAffilatesDiscountColumnConfig()}
          recordFooterVariant="secondary"
          borderedFields={true}
          fieldsAlignment="space-between"
          drawerFieldsBordered
          hasMobileFooter={true}
          tabConfig={detail_tab_config(affiliateUuid)}
          footerProps={footerProps}
          tableFilters={table_filters}
          {...affiliate_detail_back_action}
        />

        {showDiscountCodesModal && <AddAffliateDiscountModal />}

        {showDiscountCodesDeletModal && (
          <ExlyDeleteModal
            open={showDiscountCodesDeletModal}
            onClose={closeDiscountCodesDeleteModal}
            onDelete={deleteDiscountCode}
            title="Confirm Deletion"
            description="Are you sure deleting the discount code?"
          />
        )}

        {showSuccessModal && (
          <DiscountSuccessModal affiliateUuid={affiliateUuid} />
        )}
      </div>
    </>
  );
});
