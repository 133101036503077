import { useDispatch, useSelector } from "react-redux";
import { getAffliateProfile } from "./getAffliateProfileDetails";
import { EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL } from "features/AffiliateMarketing/redux/Affliates.actions";
import { logError } from "utils/error";
import { isRequestSuccessful } from "utils/Utils";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useState } from "react";
import { updateAffiliateProfileCall } from "features/AffiliateMarketing/utils/AffiliateMarketing.apiCalls";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { AFFILIATE_TRANSACTION_UPDATE_API } from "features/AffiliateMarketing/constants/Affliates.api";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { is_empty } from "utils/validations";
import { useGetCommissionPayload } from "../../AffiliateCommissionForm/utils/useGetCommissionPayload";
import { getInitialData } from "../../UpdateAffliateDetailsModal/utils/UpdateAffliateFormContent.utils";

const useAffiliateProfile = () => {
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const { affiliateUuid } = useGetAffiliateUuid();
  const { getCommissionPayload } = useGetCommissionPayload();

  const affliateDetail = useSelector(
    (state) => state.affiliateMarketing.affliateDetail
  );

  const [processing, setProcessing] = useState(false);

  const fetchAffiliateProfile = async () => {
    try {
      const data = await getAffliateProfile(affiliateUuid);
      dispatch({
        type: EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL,
        payload: data.affiliate,
      });
    } catch (err) {
      logError({
        err,
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateDetail/utils/useAffliateProfile.js",
        when: "calling fetchAffiliateProfile",
      });
    }
  };

  const updateAffiliateProfile = async (values, handleClose) => {
    if (processing) return;
    setProcessing(true);
    try {
      const initialValues = getInitialData(affliateDetail);

      const payload = await getCommissionPayload({
        currentValues: values,
        initialValues,
      });
      payload.phone_number = values.phone_number.replace(/\D/g, "").slice(2); // remove country code from phone_number & `-`

      const response = await updateAffiliateProfileCall({
        affiliateUuid,
        updatedValues: payload,
      });
      if (isRequestSuccessful(response?.status)) {
        fetchAffiliateProfile();
        notify(response?.message, notification_color_keys.success);
        handleClose();
      }
    } catch (error) {
      logError({
        error,
        when: "updateAffiliateProfile",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliateDetail/utils/useAffliateProfile.js",
      });
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      setProcessing(false);
    }
  };

  const updateCommissionStatus = async ({ status, record }) => {
    if (processing || is_empty(record?.uuid) || is_empty(status)) return;
    setProcessing(true);
    try {
      const response = await dataProvider.custom_request(
        AFFILIATE_TRANSACTION_UPDATE_API,
        apiMethods.POST,
        {
          commission_payout_status: status,
          transaction_uuid: record?.uuid,
        }
      );

      if (isRequestSuccessful(response?.status)) {
        notify(response?.message, notification_color_keys.info);
      }
    } catch (error) {
      notify(
        error?.body?.message ?? SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    } finally {
      setProcessing(false);
    }
  };

  return {
    fetchAffiliateProfile,
    updateAffiliateProfile,
    updateCommissionStatus,
    affliateDetail,
    processing,
  };
};

export default useAffiliateProfile;
