import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import moduleStyle from "./AddAffliateSuccessModal.module.css";
import { Button } from "@my-scoot/component-library-legacy";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

export const AddAffliateSuccessModal = ({ open, onClose }) => {
  return (
    <ExlyModal
      open={open}
      customHeader={<></>}
      customFooter={<></>}
      onPrimaryBtnClick={() => onClose()}
      modal_props={{
        modalPaperClassName: moduleStyle.modalWrapper,
      }}
      className={`p-0`}
    >
      <div className={moduleStyle.container}>
        <ExlyImage
          path="assets/images/affiliates/verify.svg"
          className={moduleStyle.verifyImg}
        />
        <span className={`text-center ${moduleStyle.text}`}>
          The affiliate has been successfully added!
        </span>
        <p className={`text-center`}>
          A mail has been sent to the affiliate with details to login & start
          promotion.
        </p>

        <Button
          color="primary"
          className={moduleStyle.secondaryButton}
          buttonWrapperClassName={moduleStyle.buttonWrapperClassName}
          variant="outlined"
          onClick={onClose}
        >
          {" "}
          Okay!{" "}
        </Button>
      </div>
    </ExlyModal>
  );
};
