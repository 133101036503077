import { form_data } from "features/AffiliateMarketing/constants/Affliates.constants";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL } from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch } from "react-redux";
import { navigateTo } from "utils/urlUtils";

export const useAffiliateManagement = () => {
  const dispatch = useDispatch();
  const navigateToAffiliateDetail = ({ record }) => {
    const affiliateUuid = record?.[form_data.uuid.key];
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_AFFLIATE_DETAIL,
      payload: record,
    });
    navigateTo(AFFILIATE_MARKETING_URL_KEYS.affliate_detail, {
      args: affiliateUuid,
    });
  };

  return { navigateToAffiliateDetail };
};
