import { useState } from "react";
import { INITIAL_STAT_VALUE } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import { dataProvider } from "data";
import { FETCH_COMMISSION_STATS } from "features/AffiliateMarketing/constants/Affliates.api";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful, roundOff } from "utils/Utils";
import { logError } from "utils/error";
import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";
import { useDateRangeSelector } from "features/AffiliateMarketing/utils/useDateRangeSelector";

export const useAffliatesTransactionStats = () => {
  const [statsData, setStatsData] = useState({
    total_commission: INITIAL_STAT_VALUE,
    total_credit_commission: INITIAL_STAT_VALUE,
    total_pending_commission: INITIAL_STAT_VALUE,
  });
  const [processing, setProcessing] = useState(false);

  const fetchStatsData = async ({ params }) => {
    if (processing) return;
    setProcessing(true);
    try {
      let response = await dataProvider.custom_request(
        FETCH_COMMISSION_STATS,
        apiMethods.GET,
        params
      );

      if (isRequestSuccessful(response.status)) {
        const responseData = response.data;
        let data = { ...statsData };
        for (let key in data) {
          if (responseData[key]) {
            data[key] = `${RUPEE_SYMBOL}${roundOff(responseData[key], 2)}`;
          } else {
            data[key] = 0;
          }
        }
        setStatsData(data);
      }
    } catch (error) {
      logError({
        error,
        when: "fetchStatsData",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffliatesTransactionStats/utils/useAffliatesTransactionStats.js",
      });
    } finally {
      setProcessing(false);
    }
  };

  const {
    dateRange,
    getFormattedDateRange,
    onRangeChange,
    selectedRange,
    setDateRange,
  } = useDateRangeSelector({ fetchStatsData });

  return {
    selectedRange,
    onRangeChange,
    statsData,
    getFormattedDateRange,
    dateRange,
    setDateRange,
  };
};
