import React from "react";
import {
  getCustomerNameConfig,
  getCustomerEmailConfig,
  getCustomerPhoneConfig,
  getSessionNameConfig,
  getListingTypeConfig,
  getPaymentTypeConfig,
  getFeatureDiscountCodeConfig,
  getAffliateDiscountCodeConfig,
  getTransactionIdConfig,
  getCreatedAtConfig,
  getGuestCountConfig,
  getIsInternationalConfig,
  getReceiptUrlConfig,
  getAffliateCommissionAmountConfig,
  getCommissionPayoutDateConfig,
  getAffliateCommissionPayoutStatusConfig,
  getTransactionAmountMinFilterConfig,
  getTransactionAmountMaxFilterConfig,
} from "features/AffiliateMarketing/modules/AffliateDetail/constants/detailColumn.config";
import moduleStyle from "../AffliateTransactions.module.css";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";
import {
  getGuestEmailFilterConfig,
  getGuestNameFilterConfig,
  getGuestPhoneNumberFilterConfig,
  getListingTitleFilterConfig,
  getListingTypeFilterConfig,
  getMaxCommissionPriceFilterConfig,
  getMaxPriceFilterConfig,
  getMinCommissionPriceFilterConfig,
  getMinPriceFilterConfig,
  getPaymentTypeFilterConfig,
  getTransactionAmountConfig,
  getTransactionDiscountAmountConfig,
  getTransactionPricePerSlotConfig,
} from "features/Crm/modules/Transactions/utils/Transactions.tableConfigs";
import {
  getDiscountCodeConfig,
  getCommissionPayoutStatusConfig,
  getCommissionPayoutStartConfig,
  getCommissionPayoutEndConfig,
} from "features/AffiliateMarketing/modules/AffliateDetail/constants/detailColumn.config";
import { affiliates_transactions_tab } from "./AllTransactions.constants";
import { form_data } from "./AllTransactions.constants";
import {
  AFFILIATE_MARKETING_ROUTE_IDS,
  AFFILIATE_MARKETING_URL_KEYS,
} from "features/AffiliateMarketing/constants/Affliates.urlPaths";

export const transaction_tab_config = [
  {
    label: affiliates_transactions_tab.all_transactions.label,
    value: affiliates_transactions_tab.all_transactions.value,
    path: `/${
      AFFILIATE_MARKETING_ROUTE_IDS[
        AFFILIATE_MARKETING_URL_KEYS.list_all_transcation
      ]
    }`,
  },
  {
    label: affiliates_transactions_tab.payout_summary.label,
    value: affiliates_transactions_tab.payout_summary.value,
    path: `/${
      AFFILIATE_MARKETING_ROUTE_IDS[
        AFFILIATE_MARKETING_URL_KEYS.list_all_payout_summary
      ]
    }`,
  },
];
export const allTransactionsColumnConfig = (getInvoiceURL) => [
  getCustomerNameConfig(),
  getCustomerEmailConfig(),
  getCustomerPhoneConfig(),
  getSessionNameConfig(),
  getListingTypeConfig(),
  getPaymentTypeConfig(),
  getTransactionPricePerSlotConfig({ hidePricePerSlot: false }),
  getTransactionAmountConfig({ hideTransactionAmount: false }),
  getAffliateDiscountCodeConfig(),
  getFeatureDiscountCodeConfig(),
  getTransactionDiscountAmountConfig(),
  getTransactionIdConfig(),
  getCreatedAtConfig(),
  getGuestCountConfig(),
  getIsInternationalConfig(),
  getReceiptUrlConfig(getInvoiceURL),
  {
    field: form_data.affiliate_name.key,
    headerName: form_data.affiliate_name.label,
    emptyField: "-",
    sortable: false,
    avatar: false,
    width: "200px",
    valueFormatter: (record) => (
      <NameField
        record={record}
        source={form_data.affiliate_name.key}
        className={{ name_field: moduleStyle.name_field }}
        onClick={() => {
          const uuid = record.affiliate_uuid;
          window.location.href = `${window.location.origin}/#/affliate_detail/${uuid}/all_transactions`;
        }}
      />
    ),
  },
  getAffliateCommissionAmountConfig(),
  getCommissionPayoutDateConfig(),
  getAffliateCommissionPayoutStatusConfig(true),
];
export const getAffliateNameFilterConfig = () => ({
  source: "affiliate_name",
  placeholder: "Affiliate name",
  type: "input",
  disableContains: true,
});
export const affiliateTransactionfilterConfig = [
  getAffliateNameFilterConfig(),
  getMinCommissionPriceFilterConfig(),
  getMaxCommissionPriceFilterConfig(),
  getCommissionPayoutStatusConfig(),
  getGuestNameFilterConfig(),
  getGuestEmailFilterConfig(),
  getGuestPhoneNumberFilterConfig(),
  getListingTitleFilterConfig(),
  getListingTypeFilterConfig(),
  getDiscountCodeConfig(),
  getMinPriceFilterConfig(),
  getMaxPriceFilterConfig(),
  getTransactionAmountMinFilterConfig(),
  getTransactionAmountMaxFilterConfig(),
  getPaymentTypeFilterConfig(),
  getCommissionPayoutStartConfig(),
  getCommissionPayoutEndConfig(),
];
