import React from "react";
import { Header } from "common/Components/Header";
import { useSelector } from "react-redux";
import { AffliateCommissionStats } from "../AffliateCommissionStats/AffliateCommissionStats";
import { AffliateManagementStats } from "features/AffiliateMarketing/modules/AffliateManagementStats/AffliateManagementStats";
import { is_empty } from "utils/validations";
import moduleStyle from "./AffiliateDetailHeader.module.css";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";

export const AffiliateDetailHeader = () => {
  const { affiliateUuid } = useGetAffiliateUuid();

  const affliateDetail = useSelector(
    (state) => state.affiliateMarketing.affliateDetail
  );

  return (
    <>
      <div className={`d-none d-md-block`}>
        <Header
          onBackClick={() =>
            navigateTo(AFFILIATE_MARKETING_URL_KEYS.list_affliate_management)
          }
          classNames={{ container: "px-4 border-0" }}
          title={
            <div className={`d-flex align-items-center ${moduleStyle.title} `}>
              <div>Affiliate&nbsp;Marketing</div>
              {!is_empty(affliateDetail?.full_name) && (
                <div className={`d-flex align-items-center`}>
                  &nbsp;/&nbsp;
                  <span className={`${moduleStyle.nameText}`}>
                    {affliateDetail.full_name}
                  </span>
                </div>
              )}
            </div>
          }
        />
      </div>

      <div className={`px-md-4 px-3 py-2  pb-md-2  pt-md-0`}>
        <div className={`row ${moduleStyle.statsContainer}`}>
          <div className={`h-100 col-md-7 col-12 py-2`}>
            <AffliateCommissionStats />
          </div>
          <div className={`h-100 col-md-5 col-12 py-2`}>
            <AffliateManagementStats
              affiliateUuid={affiliateUuid}
              classes={{
                containerClass: moduleStyle.AffliateManagementStatsContainer,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
