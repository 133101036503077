import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { useEffect, useState } from "react";
import { getAffliateCommissionStats } from "../../AffliateDetail/utils/getAffliateCommissionStats";
import { logError } from "utils/error";
import { isRequestSuccessful, roundOff } from "utils/Utils";
import { INITIAL_STAT_VALUE } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";

export const useAffliateCommissionStats = () => {
  const [statsData, setStatsData] = useState({
    total_commission: INITIAL_STAT_VALUE,
    total_pending_commission: INITIAL_STAT_VALUE,
    total_credit_commission: INITIAL_STAT_VALUE,
  });
  const { affiliateUuid } = useGetAffiliateUuid();

  useEffect(() => {
    const filters = { affiliate_uuid: affiliateUuid };

    (async () => {
      try {
        const response = await getAffliateCommissionStats(filters);
        if (isRequestSuccessful(response.status)) {
          const responseData = response.data;
          let data = { ...statsData };
          for (let key in data) {
            if (responseData[key]) {
              data[key] = roundOff(responseData[key], 2);
            }
          }
          setStatsData(data);
        }
      } catch (error) {
        logError({
          error,
          occuredAt:
            "src/features/AffiliateMarketing/modules/AffliateCommissionStats/utils/useAffliateCommissionStats.js",
          when: "useAffliateCommissionStats",
        });
      }
    })();
  }, []);

  return { statsData };
};
