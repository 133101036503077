import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";

export const form_data = {
  code: {
    key: "code",
    label: "Discount Code",
  },
  phone_number: {
    key: "phone_number",
    label: "Offering",
  },
  usage_count: {
    key: "usage_count",
    label: "No. of Times Used",
  },
  usage_limit: {
    key: "usage_limit",
    label: "Usage Limit",
  },
  value: {
    key: "value",
    label: "Discount Value",
  },
  expiry: {
    key: "expiry",
    label: "Validity",
  },
  applicability: {
    key: "applicability",
    label: "Validity",
  },
  type: {
    key: "type",
  },
  currency: {
    key: "currency",
  },
  listing_uuids: {
    key: "listing_uuids",
  },
  include_gst: {
    key: "include_gst",
  },
};

export const applicability_types_keys = {
  all_offerings: 1,
  specific_offerings: 2,
};

export const applicability_types = {
  [applicability_types_keys.all_offerings]: {
    value: applicability_types_keys.all_offerings,
    label: "All Offerings",
  },
  [applicability_types_keys.specific_offerings]: {
    value: applicability_types_keys.specific_offerings,
    label: "Specific Offerings",
  },
};
export const DISCOUNT_TYPE = {
  PERCENTAGE: 1,
  ABSOLUTE: 2,
};

export const discount_type = {
  [DISCOUNT_TYPE.PERCENTAGE]: "%",
  [DISCOUNT_TYPE.ABSOLUTE]: RUPEE_SYMBOL,
};
