import MoneyField from "ui/modules/MoneyField";
import React from "react";
import { column_keys } from "../../constants/ReviewSelectionList.constants";
import Tooltip from "ui/modules/Tooltip";

export const AmountField = ({ record }) => {
  return (
    <div className={`d-flex align-items-center`}>
      <MoneyField
        record={record}
        source={column_keys.affiliate_payout_amount.key}
        noRightMargin={true}
      />
      {!record.is_bank_account_connected && (
        <Tooltip
          text="You won’t be able to pay this commission until the affiliate adds their bank account details."
          className={`ml-1`}
        />
      )}
    </div>
  );
};
