import React from "react";
import { Form } from "react-final-form";
import ExlyModal from "common/Components/ExlyModal";
import { useAffiliateSettingsModal } from "./utils/useAffiliateSettingsModal";
import { useAffiliateSettingsButton } from "../../utils/useAffiliateSettingsButton";
import { is_empty } from "utils/validations";
import { AffiliateSettingsForm } from "../AffiliateSettingsForm/AffiliateSettingsForm";
import { useSetCommissionFormInitialValues } from "features/AffiliateMarketing/utils/useSetCommissionFormInitialValues";

export const AffiliateSettingsModal = () => {
  const { updateAffiliateSettings, formValues, processing } =
    useAffiliateSettingsModal();

  const { closeAffiliateSettingsModal, showAffliateSettingsModal } =
    useAffiliateSettingsButton();
  useSetCommissionFormInitialValues({ initialValues: formValues });

  if (is_empty(formValues)) return null;
  return (
    <Form
      onSubmit={updateAffiliateSettings}
      initialValues={formValues}
      keepDirtyOnReinitialize
      render={({ handleSubmit }) => (
        <ExlyModal
          open={showAffliateSettingsModal}
          onClose={closeAffiliateSettingsModal}
          desktopModalWidth="677px"
          onPrimaryBtnClick={handleSubmit}
          title={"Affiliate Settings"}
          primaryBtnText={processing ? "..." : "Update Settings"}
          className={`p-0`}
        >
          <AffiliateSettingsForm handleSubmit={handleSubmit} />
        </ExlyModal>
      )}
    />
  );
};
