import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import { useShowAddAffiliateModal } from "../../utils/useShowAddAffiliateModal";
import GroupAddTwoToneIcon from "@material-ui/icons/GroupAddTwoTone";
import moduleStyle from "./AddAffiliate.module.css";
import { AddAffiliateModal } from "../AddAffiliateModal/AddAffiliateModal";
import { AddAffliateSuccessModal } from "../AddAffliateSuccessModal/AddAffliateSuccessModal";

export const AddAffiliateButton = () => {
  const {
    openAddAffiliateModal,
    showAddAffiliateModal,
    showAddAffliateSuccessModal,
    closeAddAffiliateSuccessModal,
  } = useShowAddAffiliateModal();

  return (
    <>
      <Button
        color="primary"
        className={`d-flex align-items-center`}
        onClick={openAddAffiliateModal}
      >
        <div className={`d-flex align-items-center`}>
          <GroupAddTwoToneIcon className={`${moduleStyle.icon} mr-1`} />
          <span>Add Affiliate</span>
        </div>
      </Button>
      {showAddAffiliateModal && <AddAffiliateModal />}

      {showAddAffliateSuccessModal && (
        <AddAffliateSuccessModal
          open={showAddAffliateSuccessModal}
          onClose={closeAddAffiliateSuccessModal}
        />
      )}
    </>
  );
};
