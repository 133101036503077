import React, { useState } from "react";
import style from "./AffliateCommissionStats.module.css";
import { Avatar } from "@my-scoot/component-library-legacy";
import { Button } from "@my-scoot/component-library-legacy";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { getDateInMonthDayYearFormat } from "features/Common/DateFns.utils";
import { PayoutStatusModal } from "features/AffiliateMarketing/modules/PayoutStatusModal/PayoutStatusModal";
import { useSelector } from "react-redux";
import PayoutModal from "features/AffiliateMarketing/modules/PayoutModal/PayoutModal";
import { PayoutActions } from "./modules/PayoutActions/PayoutActions";
import { UpdateAffliateFormContent } from "../UpdateAffliateDetailsModal/UpdateAffliateDetailsModal";
import { useAffliateCommissionStats } from "./utils/useAffliateCommissionStats";

export const AffliateCommissionStats = () => {
  const selectedAffliate = useSelector(
    (state) => state.affiliateMarketing.affliateDetail
  );

  const { statsData } = useAffliateCommissionStats();
  const isDesktop = useDesktopMediaQuery();

  const [showProfileDetailsModal, setShowProfileDetailsModal] = useState(false);
  return (
    <div className={`${style.wrapper} px-3 py-md-4 pt-2 pt-md-0`}>
      <div className={`h-100 row no-gutters mt-2 mt-md-1`}>
        <div
          className={`col-3 text-center d-flex flex-column align-items-center pt-md-2`}
        >
          <Avatar
            label={selectedAffliate?.full_name}
            colorIndex={0}
            className={`${style.commentAvatar} mb-3 mb-md-1`}
          />
          <div className={`mt-1 mt-md-2 ${style.afflitateTitle}`}>
            Affiliate since
          </div>
          <div className={style.afflitateDate}>
            {getDateInMonthDayYearFormat(selectedAffliate?.created_at)}
          </div>
        </div>
        <div className={`col-8 ml-3`}>
          <div>
            <div className={`${style.name} line_max_1 mb-3`}>
              {selectedAffliate?.full_name}
            </div>
            <div
              className={`${style.commissionDetails} mb-3 no-gutters row align-items-center justify-content-center`}
            >
              <div className={`${style.heading} col-5 col-md-4`}>
                <div className={style.detailsWrapper}>
                  <span className={style.amount}>
                    ₹{statsData?.total_commission}
                  </span>
                  <span className={style.label}>Total&nbsp;Commision</span>
                </div>
              </div>
              <div className={`col-1`}>
                <div className={` ${style.dash}`}></div>
              </div>
              <div className={`col-3 ${style.heading}`}>
                <div className={`${style.detailsWrapper}`}>
                  <span className={style.amount}>
                    ₹{statsData?.total_pending_commission}
                  </span>
                  <span className={style.label}>Pending</span>
                </div>
              </div>
              <div className={` col-1`}>
                <div className={`${style.dash} `}></div>
              </div>
              <div className={` col-2 col-md-3 ${style.detailsWrapper}`}>
                <span className={style.amount}>
                  ₹{statsData?.total_credit_commission}
                </span>
                <span className={style.label}>Credited</span>
              </div>
            </div>
            <div className={`row no-gutters align-items-center mt-4 mb-3`}>
              <div
                className={`${style.PayoutActionsContainer} col-10 col-md-6`}
              >
                <PayoutActions />
              </div>
              <div className={`col-2 col-md-6 d-flex justify-content-end`}>
                {isDesktop ? (
                  <Button
                    color="secondary"
                    className={style.secondaryButton}
                    fullWidth
                    buttonWrapperClassName={style.buttonWrapperClassName}
                    onClick={() => setShowProfileDetailsModal(true)}
                  >
                    View Details
                  </Button>
                ) : (
                  <div className={`fit_content ${style.iconWrapper}`}>
                    <InfoOutlinedIcon
                      className={style.iconStyles}
                      onClick={() => setShowProfileDetailsModal(true)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <PayoutStatusModal />

        <PayoutModal />

        {showProfileDetailsModal && (
          <UpdateAffliateFormContent
            open={showProfileDetailsModal}
            onClose={() => {
              setShowProfileDetailsModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
