import React from "react";
import moduleStyle from "./AffiliateLabel.module.css";
import Tooltip from "ui/modules/Tooltip";
import { is_empty } from "utils/validations";

export const AffiliateLabel = ({
  formKey,
  formKeys = {},
  required = false,
  classes = [],
}) => {
  const labelClassName = classes?.labelClassName ?? "";

  if (is_empty(formKeys)) return null;
  return (
    <div className={`d-flex align-items-center`}>
      <div className={`${moduleStyle.label} ${labelClassName}`}>
        {formKeys[formKey]?.label}
        {required && <span className={`text-danger ml-1`}>*</span>}
      </div>
      {formKeys[formKey]?.tooltipText && (
        <Tooltip text={formKeys[formKey]?.tooltipText} />
      )}
    </div>
  );
};
