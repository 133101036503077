import React from "react";
import { Card } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import style from "./AffliatesStas.module.css";
import Typography from "@material-ui/core/Typography";
import Tooltip from "ui/modules/Tooltip";
import { is_empty } from "utils/validations";

export const AffliatesStatsCard = ({
  classes = { card_wrapper: "", statsNumber: "", icon: "" },
  statsNumber,
  title,
  tooltipText,
  Icon,
}) => {
  return (
    <Card
      className={classnames(style.card_wrapper_mb, classes.card_wrapper)}
      elevation={0}
    >
      <div>
        <div
          className={classnames(style.icon_wrapper_mb, classes.icon_wrapper)}
        >
          {Icon && (
            <Icon className={`${style.stats_icon_mb} ${classes.icon}`} />
          )}
          {!is_empty(tooltipText) && (
            <div
              className={classnames(
                style.info_icon_wrapper_mb,
                classes.info_icon
              )}
            >
              <Tooltip
                text={tooltipText}
                infoIconClassName={`${classes.icon} ${style.stats_icon_mb}`}
              />
            </div>
          )}
        </div>

        <Typography
          className={`${style.statsNumber} ${classes.statsNumber} mt-2`}
          component="div"
        >
          {statsNumber}
        </Typography>
      </div>

      <Typography
        className={classnames(classes.titleWrapper, style.stats_title_mb)}
        component="div"
      >
        {title}
      </Typography>
    </Card>
  );
};
