import React, { useEffect } from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import { affiliates_detail_tab } from "./constants/detail.constants";
import {
  affliateDetailChipsFormatter,
  affiliateDetailfilterConfig,
  detail_tab_config,
  getAffilatesDetailColumnConfig,
} from "./constants/detailColumn.config";
import EmptyImg from "../../../../assets/images/salesAndMarketing/discountCodes.svg";
import { AFFLIATE_ENTITY_TYPE } from "features/AffiliateMarketing/constants/Affliates.constants";
import SelectionCard from "features/BulkActions/modules/SelectionCard/SelectionCard";
import useBulkActions from "features/BulkActions/utils/hooks/useBulkActions";
import { useListController } from "react-admin";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { RESOURCE_KEYS } from "utils/OrgPermissions";
import useAffiliateProfile from "./utils/useAffliateProfile";
import { logError } from "utils/error";
import { AffiliateDetailHeader } from "../AffiliateDetailHeader/AffiliateDetailHeader";
import { useGetAffiliateUuid } from "features/AffiliateMarketing/utils/useGetAffiliateUuid";
import { AffiliateDetailMobileHeader } from "../AffiliateDetailHeader/AffiliateDetailMobileHeader";
import { affiliate_detail_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";

export const AffiliateDetails = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const { affiliateUuid } = useGetAffiliateUuid();
  const { fetchAffiliateProfile } = useAffiliateProfile();
  const {
    getTableActions,
    handleRowsSelected,
    selectedRecords,
    selectAll,
    handleSelectAll,
  } = useBulkActions(props);

  const { selectedIds, total } = useListController({
    ...props,
    perPage: DEFAULT_PER_PAGE,
  });

  const bulkActions = getTableActions(
    RESOURCE_KEYS.SUBSECTIONS.AFFILIATE.AFFLIATE_TRANSACTIONS
  );
  const emptyStateProps = {
    empty_list_icon: EmptyImg,
    alt: "No transactions yet",
    content_title: "No transactions yet",
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        await fetchAffiliateProfile();
      } catch (err) {
        logError({
          err,
          occuredAt:
            "src/features/AffiliateMarketing/modules/AffliateDeatils/index.jsx",
          when: "fecthing fetchAffiliateProfile",
        });
      }
    };
    fetchProfile();
  }, []);
  return (
    <>
      <AffiliateDetailHeader />
      <div className={` px-3 px-md-4`}>
        <ExlyTable
          entityName={AFFLIATE_ENTITY_TYPE.affiliate_transactions.label}
          ra_props={{
            ...props,
          }}
          tableTitle={<AffiliateDetailMobileHeader />}
          bulkActionComponent={
            <SelectionCard
              actions={bulkActions}
              selectedRecords={Object.keys(selectedRecords)}
              totalRecords={total}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
            />
          }
          {...emptyStateProps}
          noExportButton={true}
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          drawerFieldsAlignment="space-between"
          tableFilters={affiliateDetailfilterConfig}
          tableChipsFormatter={affliateDetailChipsFormatter()}
          customPrimaryAction={{
            label: "More Action",
            onClick: (
              row,
              rows,
              setDrawerConfig,
              setActionsDrawerConfig,
              rowIndex
            ) =>
              setDrawerConfig({
                drawerActionType: "view",
                open: true,
                row,
                rowIndex,
              }),
          }}
          selectedTab={affiliates_detail_tab.earning_and_activity.value}
          subTabSelected="all_transactions"
          allValuesSelected={selectAll}
          columnConfig={getAffilatesDetailColumnConfig()}
          borderedFields={true}
          fieldsAlignment="space-between"
          drawerFieldsBordered
          recordFooterVariant="secondary"
          tabConfig={detail_tab_config(affiliateUuid)}
          checkboxSelection
          onRowSelected={handleRowsSelected}
          showSelectAllcheckBox={true}
          selected={selectedIds}
          primaryKey="uuid"
          {...affiliate_detail_back_action}
        />
      </div>
    </>
  );
});
