import { column_keys } from "./ReviewSelectionList.constants";
import React from "react";
import { ActionField } from "../components/ActionField/ActionField";
import { AmountField } from "../components/AmountField/AmountField";

export const getColumnConfig = [
  {
    field: column_keys.affiliate_name.key,
    headerName: column_keys.affiliate_name.label,
    avatar: true,
    isPrimary: true,
    isDrawerTitle: true,
    emptyField: "N/A",
    sortable: false,
    width: "200px",
  },
  {
    field: column_keys.affiliate_payout_amount.key,
    headerName: column_keys.affiliate_payout_amount.label,
    valueFormatter: (record) => <AmountField record={record} />,
    sortable: false,
  },
  {
    field: column_keys.invoice_url.key,
    headerName: column_keys.invoice_url.label,
    valueFormatter: (record) => <ActionField record={record} />,
    sortable: false,
  },
];
