import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import AffiliateManagementHeaderStyle from "../AffiliateManagementHeader/AffiliateManagementHeader.module.css";
import { useAffiliateSettingsButton } from "./utils/useAffiliateSettingsButton";
import { AffiliateSettingsModal } from "./modules/AffiliateSettingsModal/AffiliateSettingsModal";
import ExlyImage from "features/Common/modules/ExlyImage/ExlyImage";

export const AffiliateSettingsButton = () => {
  const { openAffiliateSettingsModal, showAffliateSettingsModal, processing } =
    useAffiliateSettingsButton();

  return (
    <div>
      <Button
        color="secondary"
        variant="outlined"
        onClick={openAffiliateSettingsModal}
        className={`mr-2 d-flex align-items-center ${AffiliateManagementHeaderStyle.settingsButton}`}
      >
        <ExlyImage
          path="assets/images/affiliates/settings.svg"
          className={`${AffiliateManagementHeaderStyle.icon} ${AffiliateManagementHeaderStyle.settingsIcon} mr-1`}
        />
        <span>{processing ? "..." : "Affiliate Settings"}</span>
      </Button>

      {showAffliateSettingsModal && <AffiliateSettingsModal />}
    </div>
  );
};
