import React from "react";
import { Dropdown, DatePicker, Box } from "@my-scoot/component-library-legacy";
import { useAffliateManagementStats } from "./utils/useAffliateManagementStats";
import { date_range_select_options } from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import moduleStyle from "./AffliateManagementStats.module.css";
import { convertStringToDateObject } from "features/Common/modules/DateTime/utils/DateTime.utils";
import { STATS_KEYS } from "./constants/AffliateManagementStats.constants";
import { AffliatesStatsCard } from "../AffliatesStatsCard/AffliatesStatsCard";
import { format } from "date-fns";
import { YEAR_MONTH_DAY_FORMAT } from "features/Common/modules/DateTime/DateTime.constants";
import { useDesktopMediaQuery } from "features/Common/hooks/useDesktopMediaQuery";
import { is_empty } from "features/Common/utils/common.utils";
import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";

export const AffliateManagementStats = ({ affiliateUuid, classes = {} }) => {
  const {
    selectedRange,
    onRangeChange,
    getFormattedDateRange,
    dateRange,
    setDateRange,
    statsData,
  } = useAffliateManagementStats({ affiliateUuid });

  const isDesktop = useDesktopMediaQuery();
  const containerClass = classes.containerClass ?? "";
  const statCard = classes.statCard ?? "";

  const customSelected =
    selectedRange === date_range_select_options.custom.value;

  return (
    <Box className={`${moduleStyle.statsContainer} ${containerClass} h-100`}>
      <div
        className={`${moduleStyle.selectContainer} ${
          customSelected ? "align-items-start" : "align-items-center"
        }`}
      >
        <Dropdown
          options={Object.values(date_range_select_options)}
          value={selectedRange}
          handleChange={onRangeChange}
          placeholder="Select date range"
          className={`mr-md-4 ${moduleStyle.select}`}
        />
        {!customSelected ? (
          <p className={moduleStyle.dates}>{getFormattedDateRange}</p>
        ) : (
          <div
            className={`d-flex align-items-center justify-content-center ${moduleStyle.datePickerContainer} mr-2 mr-md-2`}
          >
            <DatePicker
              placeholder="From date"
              size={!isDesktop ? "thin" : "small"}
              value={
                dateRange?.startDate
                  ? convertStringToDateObject({
                      dateString: dateRange?.startDate,
                    })
                  : null
              }
              onChange={(date) => {
                setDateRange({
                  ...dateRange,
                  startDate: format(date, YEAR_MONTH_DAY_FORMAT),
                });
              }}
              wrapperClassName={`w-50 mr-2 ${moduleStyle.datePicker}`}
            />
            <DatePicker
              popperPlacement={isDesktop ? "bottom-end" : ""}
              size={!isDesktop ? "thin" : "small"}
              wrapperClassName={`w-50 ml-2 ${moduleStyle.datePicker}`}
              minDate={
                dateRange?.startDate
                  ? convertStringToDateObject({
                      dateString: dateRange?.startDate,
                    })
                  : null
              }
              disabled={!dateRange?.startDate}
              placeholder="To date"
              value={
                dateRange?.endDate
                  ? convertStringToDateObject({
                      dateString: dateRange?.endDate,
                    })
                  : null
              }
              onChange={(date) =>
                setDateRange({
                  ...dateRange,
                  endDate: format(date, YEAR_MONTH_DAY_FORMAT),
                })
              }
            />
          </div>
        )}
      </div>
      <div className={`row ${moduleStyle.cardsContainer} mt-3`}>
        <div className={`col-4 ${moduleStyle.statCardContainer}`}>
          <AffliatesStatsCard
            key={STATS_KEYS.total_clicks.key}
            statsNumber={statsData[STATS_KEYS.total_clicks.key]}
            Icon={STATS_KEYS.total_clicks.icon}
            title={STATS_KEYS.total_clicks.title}
            tooltipText={
              !is_empty(affiliateUuid)
                ? STATS_KEYS.total_clicks.detailTooltipText
                : STATS_KEYS.total_clicks.tooltipText
            }
            classes={{
              card_wrapper: ` ${moduleStyle.statCard} ${statCard} ${
                moduleStyle[STATS_KEYS.total_clicks.class]
              }`,
              statsNumber: moduleStyle[STATS_KEYS.total_clicks.class],
              icon: `${moduleStyle.viewIcon}`,
            }}
          />
        </div>

        <div className={`col-4 ${moduleStyle.statCardContainer}`}>
          <AffliatesStatsCard
            key={STATS_KEYS.total_bookings.key}
            statsNumber={statsData[STATS_KEYS.total_bookings.key]}
            Icon={STATS_KEYS.total_bookings.icon}
            title={STATS_KEYS.total_bookings.title}
            tooltipText={
              !is_empty(affiliateUuid)
                ? STATS_KEYS.total_bookings.detailTooltipText
                : STATS_KEYS.total_bookings.tooltipText
            }
            classes={{
              card_wrapper: `${moduleStyle.statCard} ${statCard} ${
                moduleStyle[STATS_KEYS.total_bookings.class]
              }`,
              statsNumber: moduleStyle[STATS_KEYS.total_bookings.class],
              icon: `${moduleStyle.bookingIcon}`,
            }}
          />
        </div>

        <div className={`col-4 ${moduleStyle.statCardContainer}`}>
          <AffliatesStatsCard
            key={STATS_KEYS.total_sales_revenue.key}
            statsNumber={`${RUPEE_SYMBOL}${
              statsData[STATS_KEYS.total_sales_revenue.key]
            }`}
            Icon={STATS_KEYS.total_sales_revenue.icon}
            title={STATS_KEYS.total_sales_revenue.title}
            tooltipText={
              !is_empty(affiliateUuid)
                ? STATS_KEYS.total_sales_revenue.detailTooltipText
                : STATS_KEYS.total_sales_revenue.tooltipText
            }
            classes={{
              card_wrapper: ` ${moduleStyle.statCard} ${statCard} ${
                moduleStyle[STATS_KEYS.total_sales_revenue.class]
              }`,
              statsNumber: moduleStyle[STATS_KEYS.total_sales_revenue.class],
              icon: `${moduleStyle.revenueIcon}`,
            }}
          />
        </div>
      </div>
    </Box>
  );
};
