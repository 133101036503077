import React from "react";
import { Header } from "common/Components/Header";
import moduleStyle from "./AffiliateManagementHeader.module.css";
import { AffiliateSettingsButton } from "../AffiliateSettings/AffiliateSettingsButton";
import { AddAffiliateButton } from "../AddAffiliate/modules/AddAffiliateButton/AddAffiliateButton";
import { AffliateManagementStats } from "../AffliateManagementStats/AffliateManagementStats";
import AffliatePortalCard from "features/AffiliateMarketing/modules/AffliatePortalCard/AffliatePortalCard";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";

export const AffiliateManagementHeader = () => {
  return (
    <>
      <div className={`d-none d-md-block`}>
        <Header
          onBackClick={() =>
            navigateTo(AFFILIATE_MARKETING_URL_KEYS.affiliate_marketing)
          }
          classNames={{ container: `px-4 border-0` }}
          title={
            <div className={`${moduleStyle.title}`}>Affiliate Management</div>
          }
          right={
            <div className={`d-flex align-items-center`}>
              <AffiliateSettingsButton />
              <AddAffiliateButton />
            </div>
          }
        />
      </div>

      <div className={`px-3 px-md-4 py-2 pb-md-2 pt-md-0`}>
        <div className={`row ${moduleStyle.statsContainer}`}>
          <div className={`h-100 col-md-6 col-12 py-md-0 py-2`}>
            <AffliateManagementStats />
          </div>
          <div className={`h-100 col-md-6 col-12 py-md-0 py-2`}>
            <AffliatePortalCard description="Share this link with your affiliates so that they can get access to your affiliate portal" />
          </div>
        </div>
      </div>
    </>
  );
};
