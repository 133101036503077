export const affiliates_transactions_tab = {
  all_transactions: {
    label: "All Transactions",
    value: 4,
  },
  payout_summary: {
    label: "Payout Summary",
    value: 1,
  },
};
export const form_data = {
  affiliate_name: {
    key: "affiliate_name",
    label: "Affiliate",
  },
};
