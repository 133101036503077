import React, { useMemo } from "react";
import { Field, useForm } from "react-final-form";
import {
  APPROVED_OFFERINGS_OPTIONS,
  COMMISION_FOR_INSTAMENTS_OPTIONS,
  COMMISION_FOR_SUBSCRIPTION_OPTIONS,
  commission_form_keys,
  CONVERSION_PERIOD_OPTIONS,
} from "./constants/AffiliateCommissionForm.constant";
import { AffiliateLabel } from "../AffiliateLabel/AffiliateLabel";
import {
  required,
  validateNaturalNumber,
} from "features/Common/modules/Form/utils/Form.validations.utils";
import { ExlyDropdown, ExlyInput, ExlyOfferingSelect } from "common/form";
import moduleStyle from "./AffiliateCommissionForm.module.css";
import { useAffiliateCommissionForm } from "./utils/useAffiliateCommissionForm";
import { is_empty } from "features/Common/utils/common.utils";
import ExlyLoader from "ui/modules/ExlyLoader";
import { validatePercentage } from "features/AffiliateMarketing/utils/AffiliateMarketing.utils";

export const AffiliateCommissionForm = ({ currentValues }) => {
  const form = useForm();

  const {
    offeringOptions,
    onSelectOffering,
    isSubscription,
    hasInstallments,
    loading,
  } = useAffiliateCommissionForm();

  const changeForm = ({ key, value }) => {
    form.change(key, value);
  };

  const allOfferingsSelected =
    currentValues?.approved_offerings_type ===
    APPROVED_OFFERINGS_OPTIONS.all_offerings.value;

  const offeringEntityFieldWidthClass = useMemo(() => {
    let widthClass = { subscription: "w-100", installment: "w-100" };
    if ((isSubscription && hasInstallments) || allOfferingsSelected) {
      widthClass = { subscription: "w-50 mr-2", installment: "w-50 ml-2" };
    }
    return widthClass;
  }, [isSubscription, hasInstallments, allOfferingsSelected]);

  if (loading) return <ExlyLoader />;

  return (
    <>
      <div className={`mb-3`}>
        <Field
          name={commission_form_keys.commission_percentage.key}
          label={
            <AffiliateLabel
              formKeys={commission_form_keys}
              formKey={commission_form_keys.commission_percentage.key}
              required
            />
          }
          validate={validatePercentage}
          size="small"
          component={ExlyInput}
          onChange={(value) =>
            changeForm({
              key: commission_form_keys.commission_percentage.key,
              value: !is_empty(value) ? Number(value) : value,
            })
          }
          type="number"
          fullWidth
          placeholder={commission_form_keys.commission_percentage.placeholder}
        />
      </div>

      <div className={`mb-3`}>
        <Field
          name={commission_form_keys.approved_offerings_type.key}
          label={
            <AffiliateLabel
              formKeys={commission_form_keys}
              formKey={commission_form_keys.approved_offerings_type.key}
              required
            />
          }
          validate={required}
          size="small"
          component={ExlyDropdown}
          fullWidth
          placeholder={commission_form_keys.approved_offerings_type.placeholder}
          options={Object.values(APPROVED_OFFERINGS_OPTIONS)}
          selectClassName={moduleStyle.dropdown}
        />
      </div>

      {currentValues?.approved_offerings_type ===
        APPROVED_OFFERINGS_OPTIONS.specific_offerings.value && (
        <div className={`mb-3`}>
          <Field
            name={commission_form_keys.affiliate_listing_map.key}
            component={ExlyOfferingSelect}
            label={
              <AffiliateLabel
                formKeys={commission_form_keys}
                formKey={commission_form_keys.affiliate_listing_map.key}
                required
              />
            }
            multiple={true}
            showSelectAll
            mobileModalTitle={
              <AffiliateLabel
                formKeys={commission_form_keys}
                formKey={commission_form_keys.affiliate_listing_map.key}
                required
              />
            }
            placeholder={commission_form_keys.affiliate_listing_map.placeholder}
            selectAllContent="Select All"
            deselectAllContent="Remove All"
            options={offeringOptions}
            fullWidth
            validate={required}
            showStatus
            onChange={onSelectOffering}
          />
        </div>
      )}

      {(allOfferingsSelected || isSubscription || hasInstallments) && (
        <div
          className={`d-flex align-items-center justify-content-between mb-3`}
        >
          {(allOfferingsSelected || isSubscription) && (
            <div className={`${offeringEntityFieldWidthClass.subscription}`}>
              <Field
                name={commission_form_keys.recurring_commission_type.key}
                label={
                  <AffiliateLabel
                    formKeys={commission_form_keys}
                    formKey={commission_form_keys.recurring_commission_type.key}
                    required
                  />
                }
                validate={required}
                size="small"
                component={ExlyDropdown}
                fullWidth
                placeholder={
                  commission_form_keys.recurring_commission_type.placeholder
                }
                options={Object.values(COMMISION_FOR_SUBSCRIPTION_OPTIONS)}
                selectClassName={moduleStyle.dropdown}
              />
            </div>
          )}
          {(allOfferingsSelected || hasInstallments) && (
            <div className={`${offeringEntityFieldWidthClass.installment}`}>
              <Field
                name={commission_form_keys.instalment_commission_type.key}
                label={
                  <AffiliateLabel
                    formKeys={commission_form_keys}
                    formKey={
                      commission_form_keys.instalment_commission_type.key
                    }
                    required
                  />
                }
                validate={required}
                size="small"
                component={ExlyDropdown}
                fullWidth
                placeholder={
                  commission_form_keys.instalment_commission_type.placeholder
                }
                options={Object.values(COMMISION_FOR_INSTAMENTS_OPTIONS)}
                selectClassName={moduleStyle.dropdown}
              />
            </div>
          )}
        </div>
      )}
      <div className={`mb-3`}>
        <Field
          name={commission_form_keys.conversion_period_type.key}
          label={
            <AffiliateLabel
              formKeys={commission_form_keys}
              formKey={commission_form_keys.conversion_period_type.key}
              required
            />
          }
          validate={required}
          size="small"
          component={ExlyDropdown}
          fullWidth
          placeholder={commission_form_keys.conversion_period_type.placeholder}
          options={Object.values(CONVERSION_PERIOD_OPTIONS)}
          selectClassName={moduleStyle.dropdown}
        />
      </div>
      {currentValues.conversion_period_type ===
        CONVERSION_PERIOD_OPTIONS.limited_days.value && (
        <div className={`mb-3`}>
          <Field
            name={commission_form_keys.conversion_period_magnitude.key}
            component={ExlyInput}
            fullWidth
            type="number"
            size="small"
            onChange={(value) =>
              changeForm({
                key: commission_form_keys.conversion_period_magnitude.key,
                value: !is_empty(value) ? Number(value) : value,
              })
            }
            label={
              <AffiliateLabel
                formKeys={commission_form_keys}
                formKey={commission_form_keys.conversion_period_magnitude.key}
                required
              />
            }
            validate={(value) => {
              let check = required(value) ?? validateNaturalNumber(value);
              if (
                value >
                commission_form_keys.conversion_period_magnitude.max_days
              )
                check = `Max ${commission_form_keys.conversion_period_magnitude.max_days} days`;
              return check;
            }}
            placeholder={
              commission_form_keys.conversion_period_magnitude.placeholder
            }
          />
        </div>
      )}
    </>
  );
};
