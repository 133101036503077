import React from "react";
import { Chip } from "@my-scoot/component-library-legacy";
import moduleStyle from "./DiscountStatusField.module.css";

export const DiscountStatusField = ({ record }) => {
  return (
    <Chip
      label={record.status}
      chipClassName={`py-2
        ${
          record.status === "Active" ? moduleStyle.active : moduleStyle.inactive
        }
      `}
    />
  );
};
