import { useSelector } from "react-redux";
import { isRequestSuccessful } from "utils/Utils";
import { useEffect, useState } from "react";
import { fetchOfferingsList } from "features/Listings/utils/Listings.utils";
import { offering_availability_statuses } from "features/Listings/Listings.constants";
import { commission_form_keys } from "../constants/AffiliateCommissionForm.constant";
import { useCheckFeatureEntity } from "features/AffiliateMarketing/utils/useCheckFeatureEntity";
import { is_empty } from "features/Common/utils/common.utils";

export const useAffiliateCommissionForm = () => {
  const { checkFeatureEntity } = useCheckFeatureEntity();
  const affiliateSettings = useSelector(
    (state) => state.affiliateMarketing.affiliateSettings
  );
  const commissionFormInitialValues = useSelector(
    (state) => state.affiliateMarketing.commissionFormInitialValues
  );

  const [isSubscription, setIsSubscription] = useState(undefined);
  const [hasInstallments, setHasInstallments] = useState(undefined);
  const [offeringOptions, setOfferingOptions] = useState(undefined);

  // incase of update affiliate details, whether or not to show subscription/installments fields initially
  useEffect(() => {
    if (isSubscription === undefined || hasInstallments === undefined) {
      const selectedOfferings =
        commissionFormInitialValues?.[
          commission_form_keys.affiliate_listing_map.key
        ];

      if (!is_empty(selectedOfferings)) {
        onSelectOffering(selectedOfferings);
      } else {
        setIsSubscription(false);
        setHasInstallments(false);
      }
    }
  }, [commissionFormInitialValues]);

  useEffect(() => {
    (async () => {
      if (offeringOptions === undefined) {
        const response = await fetchOfferingsList({
          is_offering_variants_enabled: true,
          page: "all",
          status: `${offering_availability_statuses.live},${offering_availability_statuses.sold_out}`,
        });
        if (isRequestSuccessful(response.status)) {
          if (!is_empty(response.data.listings)) {
            setOfferingOptions(
              response.data.listings?.map((listing) => ({
                ...listing,
                label: listing.title,
                value: listing.uuid,
              }))
            );
          } else {
            setOfferingOptions([]);
          }
        }
      }
    })();
  }, []);

  const onSelectOffering = async (selectedOfferingsUuid) => {
    const check = await checkFeatureEntity({ selectedOfferingsUuid });
    setIsSubscription(check.isSubscription);
    setHasInstallments(check.hasInstallments);
  };

  const loading =
    offeringOptions === undefined ||
    hasInstallments === undefined ||
    isSubscription === undefined;

  return {
    showAffliateSettingsModal: affiliateSettings.showAffiliateSettingsModal,
    formValues: affiliateSettings.formValues,
    isSubscription,
    offeringOptions,
    onSelectOffering,
    hasInstallments,
    loading,
  };
};
