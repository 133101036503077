import React from "react";
import {
  DISCOUNT_TYPE,
  discount_type,
  form_data,
} from "./affliateDiscount.constants";
import constants from "constants/constants";
import { is_empty } from "utils/validations";
import { DiscountDeleteAction } from "../modules/DiscountDeleteAction/DiscountDeleteAction";
import { ApplicableOfferingsField } from "../modules/ApplicableOfferingsField/ApplicableOfferingsField";
import { DiscountStatusField } from "../modules/DiscountStatusField/DiscountStatusField";

export const table_filters = [
  {
    source: form_data.code.key,
    placeholder: form_data.code.label,
    type: "input",
    disableContains: true,
  },
];

export const getAffilatesDiscountColumnConfig = () => [
  {
    field: form_data.code.key,
    headerName: form_data.code.label,
    emptyField: "-",
    sortable: false,
  },
  {
    field: form_data.applicability.key,
    headerName: form_data.applicability.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => <ApplicableOfferingsField record={record} />,
  },
  {
    field: form_data.usage_count.key,
    headerName: form_data.usage_count.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>{record.usage_count === 0 ? "0" : record.usage_count || "-"}</span>
    ),
  },
  {
    field: form_data.usage_limit.key,
    headerName: form_data.usage_limit.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
  },
  {
    field: form_data.value.key,
    headerName: form_data.value.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {record.type === DISCOUNT_TYPE.PERCENTAGE ? (
          `${record.value}${discount_type[DISCOUNT_TYPE.PERCENTAGE]}`
        ) : (
          <>
            {is_empty(record.currency)
              ? constants.CURRENCY_SYMBOL_MAP.INR
              : constants.CURRENCY_SYMBOL_MAP[record.currency]}
            {record.value}
          </>
        )}
      </span>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    valueFormatter: (record) => <DiscountStatusField record={record} />,
    noWrap: true,
    sortable: false,
    hidden: true,
  },
  {
    field: "",
    headerName: "Action",
    valueFormatter: (record) => <DiscountDeleteAction record={record} />,
    sortable: false,
    noWrap: true,
    hidden: true,
  },
];
