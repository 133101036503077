import React from "react";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useAffiliateProfile from "../AffliateDetail/utils/useAffliateProfile";
import moduleStyle from "./AffiliateDetailHeader.module.css";
import { is_empty } from "utils/validations";

export const AffiliateDetailMobileHeader = () => {
  const { affliateDetail } = useAffiliateProfile();
  const isDesktop = useDesktopMediaQuery();

  if (isDesktop) return null;

  return (
    <div className={`${moduleStyle.title}`}>
      Affiliate Marketing
      {!is_empty(affliateDetail?.full_name) && (
        <span>
          &nbsp;/&nbsp;
          <span className={`${moduleStyle.nameText}`}>
            {affliateDetail.full_name}
          </span>
        </span>
      )}
    </div>
  );
};
