import React, { useState } from "react";
import { Dropdown } from "@my-scoot/component-library-legacy";
import {
  COMMISSION_PAYOUT_STATUS,
  COMMISSION_PAYOUT_STATUS_KEYS,
} from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import useAffiliateProfile from "../../utils/useAffliateProfile";

export const CommissionStatusField = ({ record = {} }) => {
  const { updateCommissionStatus } = useAffiliateProfile();

  const [value, setValue] = useState(record?.commission_payout_status);

  return (
    <div className={`d-flex align-items-center`}>
      <Dropdown
        placeholder="Affiliate Status"
        value={value}
        handleChange={(value) => {
          updateCommissionStatus({ status: value, record });
          setValue(value);
        }}
        options={[
          COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.approved],
          COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.pending],
          COMMISSION_PAYOUT_STATUS[COMMISSION_PAYOUT_STATUS_KEYS.rejected],
        ]}
        width={"120px"}
      />
    </div>
  );
};
