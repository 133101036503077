import { addChangeStateToArray } from "features/Common/modules/Form/utils/Form.utils";
import {
  APPROVED_OFFERINGS_OPTIONS,
  commission_form_keys,
} from "../constants/AffiliateCommissionForm.constant";
import { logError } from "utils/error";
import { useCheckFeatureEntity } from "features/AffiliateMarketing/utils/useCheckFeatureEntity";
import { is_empty } from "features/Common/utils/common.utils";

export const useGetCommissionPayload = () => {
  const { checkFeatureEntity } = useCheckFeatureEntity();
  const getCommissionPayload = async ({ initialValues, currentValues }) => {
    try {
      const updatedValues = { ...initialValues, ...currentValues };
      if (
        !is_empty(
          initialValues[commission_form_keys.affiliate_listing_map.key]
        ) ||
        currentValues.approved_offerings_type ===
          APPROVED_OFFERINGS_OPTIONS.specific_offerings.value
      ) {
        const newArray =
          currentValues.approved_offerings_type ===
          APPROVED_OFFERINGS_OPTIONS.all_offerings.value
            ? []
            : currentValues[commission_form_keys.affiliate_listing_map.key];
        const offeringList = addChangeStateToArray({
          oldArray: initialValues[
            commission_form_keys.affiliate_listing_map.key
          ].map((uuid) => ({ uuid })),
          newArray: newArray.map((uuid) => ({
            uuid,
          })),
        });

        updatedValues[commission_form_keys.affiliate_listing_map.key] =
          offeringList;
      }

      const check = await checkFeatureEntity({
        selectedOfferingsUuid: updatedValues?.affiliate_listing_map.map(
          (item) => item.uuid
        ),
      });
      if (
        updatedValues.approved_offerings_type ===
        APPROVED_OFFERINGS_OPTIONS.specific_offerings.value
      ) {
        if (!check.isSubscription)
          delete updatedValues.recurring_commission_type;
        if (!check.hasInstallments)
          delete updatedValues.instalment_commission_type;
      }

      return updatedValues;
    } catch (error) {
      logError({
        error,
        when: "getCommissionPayload",
        occuredAt:
          "src/features/AffiliateMarketing/modules/AffiliateCommissionForm/utils/useGetCommissionPayload.js",
      });
    }
  };

  return { getCommissionPayload };
};
