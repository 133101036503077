import React from "react";
import {
  getPayoutAmountConfig,
  PAYOUT_STATUS,
  getPayoutDateConfig,
  getReceiptURLConfig,
  getMinPayoutAmountFilterConfig,
  getPayoutStatusFilterConfig,
  getPayoutDateStartRangeFilterConfig,
  getPayoutDateEndRangeFilterConfig,
  getMaxPayoutAmountFilterConfig,
} from "features/AffiliateMarketing/modules/PayoutSummary/constants/payout.constants";
import moduleStyle from "../TransactionPayout.module.css";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";
import { PayoutStatusField } from "features/AffiliateMarketing/modules/PayoutSummary/modules/PayoutStatusField/PayoutStatusFIeld";
import { navigateTo } from "utils/urlUtils";
import { AFFILIATE_MARKETING_URL_KEYS } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import EmptyImg from "../../../../../../../assets/images/salesAndMarketing/discountCodes.svg";

export const emptyStateProps = {
  empty_list_icon: EmptyImg,
  alt: "No payouts yet",
  content_title: "No payouts yet",
};

export const column_keys = {
  affiliate_name: "affiliate_name",
  payout_status: "payout_status",
};

export const getExtendedAffilatesPayoutColumnConfig = (getInvoiceURL) => [
  {
    field: column_keys.affiliate_name,
    headerName: "Affiliate",
    emptyField: "-",
    sortable: false,
    noWrap: true,
    isPrimary: true,
    fixed: "left",
    avatar: true,
    valueFormatter: (record) => (
      <NameField
        record={record}
        source={column_keys.affiliate_name}
        className={{ name_field: moduleStyle.name_field }}
        onClick={() => {
          const affiliateUuid = record.affiliate_uuid;
          navigateTo(AFFILIATE_MARKETING_URL_KEYS.affliate_detail, {
            args: affiliateUuid,
          });
        }}
      />
    ),
  },
  getPayoutAmountConfig(),
  getPayoutDateConfig(),
  getReceiptURLConfig(getInvoiceURL),
  {
    field: column_keys.payout_status,
    headerName: "Status",
    valueFormatter: (record) => (
      <PayoutStatusField
        record={record}
        source={column_keys.payout_status}
        dropdown={PAYOUT_STATUS}
        showRetryButton={record.payout_status === PAYOUT_STATUS.failed.value}
      />
    ),
    sortable: false,
    noWrap: true,
    align: "left",
  },
];
export const affliatTransactionPayoutfilterConfig = [
  getMinPayoutAmountFilterConfig(),
  getMaxPayoutAmountFilterConfig(),
  getPayoutStatusFilterConfig(),
  getPayoutDateStartRangeFilterConfig(),
  getPayoutDateEndRangeFilterConfig(),
];
