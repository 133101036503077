import { COMISSION_PAYOUT_TYPE_IDS } from "features/AffiliateMarketing/modules/AffliateDetail/constants/detail.constants";
import { useAllTransactions } from "../../../utils/useAllTransactions";

export const usePayoutCommissionButton = () => {
  const { initiateTransactionsReview } = useAllTransactions();
  const dropdown_options = [
    {
      value: COMISSION_PAYOUT_TYPE_IDS.approved_only,
      onClick: () =>
        initiateTransactionsReview({
          payload: {
            commission_payout_type: COMISSION_PAYOUT_TYPE_IDS.approved_only,
          },
        }),
      label: "Payout Approved Commissions",
    },
    {
      value: COMISSION_PAYOUT_TYPE_IDS.approved_and_pending,
      onClick: () =>
        initiateTransactionsReview({
          payload: {
            commission_payout_type:
              COMISSION_PAYOUT_TYPE_IDS.approved_and_pending,
          },
        }),
      label: "Payout Pending & approved commissions",
    },
  ];

  return { dropdown_options };
};
