import { app_route_ids, app_route_keys } from "constants/urlPaths";

const pathName = app_route_ids[app_route_keys.review_selection_list_modal];

export const REVIEW_SELECTION_LIST_RA_PROPS = {
  basePath: pathName,
  history: {
    location: {
      pathname: pathName,
      search: "",
      hash: "",
    },
  },
  location: {
    pathname: pathName,
    search: "",
    hash: "",
  },
  match: {
    path: pathName,
    url: pathName,
    isExact: true,
    params: {},
  },

  resource: pathName,
  hasList: true,
  hasEdit: false,
  hasShow: false,
  hasCreate: false,
  syncWithLocation: false,
};

export const column_keys = {
  affiliate_name: {
    key: "affiliate_name",
    label: "Affiliate Name",
  },
  affiliate_payout_amount: {
    key: "affiliate_payout_amount",
    label: "Payout amount",
  },
  invoice_url: {
    key: "invoice_url",
    label: "Invoice",
  },
};
