import React from "react";
import moduleStyle from "./NameField.module.css";
import { is_empty } from "utils/validations";
import { useAffiliateManagement } from "../../utils/useAffiliateManagement";

export const NameField = ({ record = {}, source }) => {
  const { navigateToAffiliateDetail } = useAffiliateManagement();
  if (is_empty(record[source])) return "N/A";

  return (
    <span
      onClick={() => navigateToAffiliateDetail({ record })}
      className={`${moduleStyle.name_field}`}
    >
      {record[source]}
    </span>
  );
};
