export const form_keys = {
  account_full_name: "account_details.full_name",
  account_number: "account_details.account_number",
  account_type: "account_details.account_type",
  bank_name: "account_details.bank_name",
  ifsc_code: "account_details.ifsc_code",
  pan_number: "account_details.pan_number",
  billing_state: "account_details.billing_state",
  gst_info: {
    gst_name: "account_details.gst_info.gst_name",
    gst_number: "account_details.gst_info.gst_number",
    gst_company_address: "account_details.gst_info.gst_company_address",
  },
};
