import { affiliates_detail_tab } from "./detail.constants";
import EmailField from "ui/Fields/EmailField";
import { PayoutStatusField } from "../../PayoutSummary/modules/PayoutStatusField/PayoutStatusFIeld";
import { InvoiceField } from "../../FormFields/InvoiceField/InvoiceField";
import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";
import { form_data } from "./detail.constants";
import {
  listingTypeOptions,
  ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS,
} from "ui/pages/transactions/AllTransaction/AllTransactionTableConfig";
import constants from "constants/constants";
import moment from "moment";
import {
  getGuestEmailFilterConfig,
  getGuestNameFilterConfig,
  getGuestPhoneNumberFilterConfig,
  getListingTitleFilterConfig,
  getListingTypeFilterConfig,
  getMaxCommissionPriceFilterConfig,
  getMaxPriceFilterConfig,
  getMinCommissionPriceFilterConfig,
  getMinPriceFilterConfig,
  getPaymentTypeFilterConfig,
  getTransactionAmountConfig,
  getTransactionDiscountAmountConfig,
  getTransactionPricePerSlotConfig,
} from "features/Crm/modules/Transactions/utils/Transactions.tableConfigs";
import {
  COMMISSION_PAYOUT_STATUS,
  COMMISSION_PAYOUT_STATUS_FILTERS,
  COMMISSION_PAYOUT_STATUS_KEYS,
} from "features/AffiliateMarketing/constants/AffiliateMarketing.constants";
import React from "react";
import { NameField } from "ui/pages/customers/AllCustomers/components/TableColumns/TableColumns";
import moduleStyle from "../AffliateDetail.module.css";
import { ORDER_PAYMENT_TYPE_LABELS } from "schedule-v2/constants";
import { is_empty } from "features/Common/utils/common.utils";
import { roundOff } from "utils/Utils";
import { getDateInMonthDayYearTimeFormat } from "features/Common/DateFns.utils";
import { CommissionStatusField } from "../modules/CommissionStatusField/CommissionStatusField";

export const detail_tab_config = (id) => [
  {
    label: affiliates_detail_tab.earning_and_activity.label,
    value: affiliates_detail_tab.earning_and_activity.value,
    subTabs: [
      {
        label: "All Transactions",
        value: "all_transactions",
        path: `/affliate_detail/${id}/all_transactions`,
      },
      {
        label: "Offering Wise Report",
        value: "offering_wise_report",
        path: `/affliate_detail/${id}/offering-wise-report`,
      },
      {
        label: "Payout Summary",
        value: "payout_summary",
        path: `/affliate_detail/${id}/payout_summary`,
      },
    ],
    path: `/affliate_detail/${id}/all_transactions`,
  },
  {
    label: affiliates_detail_tab.discount_codes.label,
    value: affiliates_detail_tab.discount_codes.value,
    path: `/affliate_detail/${id}/discount_code`,
  },
];

export const getAffilatesDetailColumnConfig = () => [
  getCustomerNameConfig(),
  getCustomerEmailConfig(),
  getCustomerPhoneConfig(),
  getSessionNameConfig(),
  getListingTypeConfig(),
  getPaymentTypeConfig(),
  getTransactionPricePerSlotConfig({ hidePricePerSlot: false }),
  getTransactionAmountConfig({ hideTransactionAmount: false }),
  getAffliateDiscountCodeConfig(),
  getFeatureDiscountCodeConfig(),
  getTransactionDiscountAmountConfig(),
  getTransactionIdConfig(),
  getCreatedAtConfig(),
  getGuestCountConfig(),
  getIsInternationalConfig(),
  getReceiptUrlConfig(),
  getAffliateCommissionAmountConfig(),
  getCommissionPayoutDateConfig(),
  getAffliateCommissionPayoutStatusConfig(true),
];

export const affliateDetailChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case form_data.name.key:
      return `Name: ${value}`;
    case `${form_data.email.key}__icontains`:
      return `Email: ${value}`;
    case `${form_data.session_name.filterKey}__icontains`:
      return `Offering Name: ${value}`;
    case `${form_data.customer_phone.filterKey}__contains`:
      return `Phone: ${value}`;
    case form_data.min_price.key:
      return `Minimum Price: ${value}`;
    case form_data.max_price.key:
      return `Maximum Price: ${value}`;
    case form_data.min_commission_amount.key:
      return `Minimum commission amount: ${value}`;
    case form_data.max_commission_amount.key:
      return `Maximum commission amount: ${value}`;
    case form_data.commission_payout_status.key:
      return `Commission Payout Status: ${COMMISSION_PAYOUT_STATUS[value].label}`;
    case form_data.discount_code.key:
      return `Discount Code: ${value}`;
    case form_data.session_type.filterKey: {
      const listing = listingTypeOptions?.find((item) => item.id == value);
      return `Offering Type: ${listing?.name}`;
    }
    case form_data.payment_type.key:
      return `Payment plan Type: ${ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS[value]}`;
    case `${form_data.commission_payout_date.key}__start_range`:
      return `Commission start date: ${moment(value).format(
        constants.displayDateYearFormat
      )}`;
    case `${form_data.commission_payout_date.key}__end_range`:
      return `Commission end date: ${moment(value).format(
        constants.displayDateYearFormat
      )}`;
    case `${form_data.created_at.key}__start_range`:
      return `Transaction start date: ${moment(value).format(
        constants.displayDateYearFormat
      )}`;
    case `${form_data.created_at.key}__end_range`:
      return `Transaction end date: ${moment(value).format(
        constants.displayDateYearFormat
      )}`;
    case form_data.transaction_amount.max:
      return `Max transaction Amount: ${value}`;
    case form_data.transaction_amount.min:
      return `Min transaction Amount: ${value}`;
    case form_data.affiliate_name.key:
      return `Affiliate name: ${value}`;
    default:
      return undefined;
  }
};
export const getTransactionStartConfig = () => ({
  source: "created_at__start_range",
  placeholder: "Transaction date from",
  type: "date",
  disableContains: true,
});
export const getTransactionEndConfig = () => ({
  source: "created_at__end_range",
  placeholder: "Transaction date to",
  type: "date",
  disableContains: true,
});
export const getCommissionPayoutStartConfig = () => ({
  source: "commission_payout_date__start_range",
  placeholder: "Commission payout date from",
  type: "date",
  disableContains: true,
});
export const getCommissionPayoutEndConfig = () => ({
  source: "commission_payout_date__end_range",
  placeholder: "Commission payout date to",
  type: "date",
  disableContains: true,
});
export const getCommissionPayoutStatusConfig = () => ({
  source: "commission_payout_status",
  placeholder: "Commission payout status",
  type: "dropdown",
  options: COMMISSION_PAYOUT_STATUS_FILTERS,
  disableContains: true,
});
export const getCommissionAmountConfig = () => ({
  source: "min_commission_amount",
  placeholder: "Min amount ",
  type: "input",
  disableContains: true,
});
export const getDiscountCodeConfig = () => ({
  source: form_data.discount_code.key,
  placeholder: form_data.discount_code.label,
  type: "input",
  disableContains: true,
});
export const getTransactionAmountMinFilterConfig = () => ({
  source: form_data.transaction_amount.min,
  placeholder: `Min ${form_data.transaction_amount.label}`,
  type: "input",
  disableContains: true,
});

export const getTransactionAmountMaxFilterConfig = () => ({
  source: form_data.transaction_amount.max,
  placeholder: `Max ${form_data.transaction_amount.label}`,
  type: "input",
  disableContains: true,
});

export const affiliateDetailfilterConfig = [
  getGuestNameFilterConfig(),
  getGuestEmailFilterConfig(),
  getListingTitleFilterConfig(),
  getGuestPhoneNumberFilterConfig(),
  getMinPriceFilterConfig(),
  getMaxPriceFilterConfig(),
  getTransactionAmountMinFilterConfig(),
  getTransactionAmountMaxFilterConfig(),
  getTransactionStartConfig(),
  getTransactionEndConfig(),
  getListingTypeFilterConfig(),
  getPaymentTypeFilterConfig(),
  getDiscountCodeConfig(),
  getMinCommissionPriceFilterConfig(),
  getMaxCommissionPriceFilterConfig(),
  getCommissionPayoutStatusConfig(),
  getCommissionPayoutStartConfig(),
  getCommissionPayoutEndConfig(),
];

export const getCustomerNameConfig = () => ({
  field: form_data.customer_name.key,
  headerName: form_data.customer_name.label,
  emptyField: "-",
  sortable: false,
  fixed: "left",
  width: "200px",
  avatar: true,
  isPrimary: true,
  valueFormatter: (record) => (
    <NameField
      record={record}
      source={form_data.customer_name.key}
      className={{ name_field: moduleStyle.name_Field }}
    />
  ),
});

export const getCustomerEmailConfig = () => ({
  field: form_data.customer_email.key,
  headerName: form_data.customer_email.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  valueFormatter: (record) =>
    record.customer_email ? (
      <EmailField record={record} source={form_data.customer_email.key} />
    ) : (
      "-"
    ),
});

export const getCustomerPhoneConfig = () => ({
  field: form_data.customer_phone.key,
  headerName: form_data.customer_phone.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  valueFormatter: (record) => (
    <WhatsAppPhoneField
      record={record}
      source="customer_phone"
      countryCodeKey="customer_country_code"
    />
  ),
});

export const getSessionNameConfig = () => ({
  field: form_data.session_name.key,
  headerName: form_data.session_name.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
});

export const getListingTypeConfig = () => ({
  field: form_data.listing_type.key,
  headerName: form_data.listing_type.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  valueFormatter: (record) =>
    constants.schedule_type[record.listing_type]?.name ?? "N/A",
});

export const getPaymentTypeConfig = () => ({
  field: form_data.payment_type.key,
  headerName: form_data.payment_type.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  valueFormatter: (record) => {
    const { payment_type } = record;
    return payment_type ? ORDER_PAYMENT_TYPE_LABELS[payment_type] : "-";
  },
});

export const getPriceConfig = () => ({
  field: form_data.price.key,
  headerName: form_data.price.label,
  emptyField: "-",
  sortable: false,
  isCollapsed: true,
  noWrap: true,
  valueFormatter: (record) => (
    <span>
      {is_empty(record.currency)
        ? constants.CURRENCY_SYMBOL_MAP["INR"]
        : constants.CURRENCY_SYMBOL_MAP[record.currency]}
      {roundOff(record.price, 2)}
    </span>
  ),
});

export const getAffliateDiscountCodeConfig = () => ({
  field: form_data.discount_code.key,
  headerName: form_data.discount_code.label,
  emptyField: "-",
  isCollapsed: true,
  sortable: false,
  noWrap: true,
  valueFormatter: (record) => {
    const code = record?.[form_data.discount_code.key];
    return is_empty(code) ? "-" : code;
  },
});

export const getFeatureDiscountCodeConfig = () => ({
  field: form_data.feature_discount_code.key,
  headerName: form_data.feature_discount_code.label,
  emptyField: "-",
  sortable: false,
  isCollapsed: true,
  noWrap: true,
  valueFormatter: (record) => {
    const lto = record?.[form_data.feature_discount_code.key];
    return is_empty(lto) ? "-" : lto;
  },
});

export const getTransactionIdConfig = () => ({
  field: form_data.transaction_id.key,
  headerName: form_data.transaction_id.label,
  emptyField: "-",
  sortable: false,
  isCollapsed: true,
  noWrap: true,
});

export const getCreatedAtConfig = () => ({
  field: form_data.created_at.key,
  headerName: form_data.created_at.label,
  emptyField: "-",
  isCollapsed: true,
  sortable: false,
  noWrap: true,
  valueFormatter: (record) =>
    getDateInMonthDayYearTimeFormat(record.created_at),
});

export const getGuestCountConfig = () => ({
  field: form_data.guest_count.key,
  headerName: form_data.guest_count.label,
  emptyField: "-",
  isCollapsed: true,
  sortable: false,
  noWrap: true,
});

export const getIsInternationalConfig = () => ({
  field: form_data.is_international.key,
  headerName: form_data.is_international.label,
  emptyField: "-",
  isCollapsed: true,
  sortable: false,
  noWrap: true,
  valueFormatter: (record) =>
    record[form_data.is_international.key] ? "Yes" : "No",
});

export const getReceiptUrlConfig = () => ({
  field: form_data.receipt_url.key,
  headerName: form_data.receipt_url.label,
  emptyField: "-",
  isCollapsed: true,
  sortable: false,
  noWrap: true,
  valueFormatter: (record) => <InvoiceField record={record} />,
});

export const getAffliateCommissionAmountConfig = () => ({
  field: form_data.commission_amount.key,
  headerName: form_data.commission_amount.label,
  emptyField: "-",
  sortable: false,
  isCollapsed: true,
  noWrap: true,
  valueFormatter: (record) => (
    <span>
      {is_empty(record.commission_payout_currency)
        ? constants.CURRENCY_SYMBOL_MAP["INR"]
        : constants.CURRENCY_SYMBOL_MAP[record.commission_payout_currency]}
      {roundOff(record.commission_amount, 2)}
    </span>
  ),
});

export const getDiscountAmountConfig = () => ({
  field: form_data.discount_amount.key,
  headerName: form_data.discount_amount.label,
  emptyField: "-",
  sortable: false,
  isCollapsed: true,
  noWrap: true,
  valueFormatter: (record) =>
    is_empty(record.discount_amount) ? (
      "-"
    ) : (
      <span>
        {is_empty(record.currency)
          ? constants.CURRENCY_SYMBOL_MAP["INR"]
          : constants.CURRENCY_SYMBOL_MAP[record.currency]}
        {roundOff(record.discount_amount, 2)}
      </span>
    ),
});

export const getCommissionPayoutDateConfig = () => ({
  field: form_data.commission_payout_date.key,
  headerName: form_data.commission_payout_date.label,
  emptyField: "-",
  sortable: false,
  noWrap: true,
  isCollapsed: true,
  valueFormatter: (record) =>
    record.commission_payout_date
      ? getDateInMonthDayYearTimeFormat(record.commission_payout_date)
      : "-",
});

export const getAffliateCommissionPayoutStatusConfig = (hideRetry = false) => ({
  field: "",
  headerName: "Commission Payout Status",
  valueFormatter: (record) => {
    if (
      [
        COMMISSION_PAYOUT_STATUS_KEYS.approved,
        COMMISSION_PAYOUT_STATUS_KEYS.pending,
        COMMISSION_PAYOUT_STATUS_KEYS.rejected,
      ].includes(record.commission_payout_status)
    )
      return (
        <CommissionStatusField
          record={record}
          source="commission_payout_status"
        />
      );

    return (
      <PayoutStatusField
        record={record}
        source={"commission_payout_status"}
        hideRetry={hideRetry}
      />
    );
  },
  sortable: false,
  noWrap: true,
  isCollapsed: true,
});
