import ExlyImage from "common/Components/ExlyImage";
import React from "react";
import DeleteIcon from "assets/images/delete_lined.svg";
import { useShowDiscountCodesModal } from "../../utils/useShowDiscountCodesModal";

export const DiscountDeleteAction = ({ record }) => {
  const { openDiscountCodesDeleteModal } = useShowDiscountCodesModal();
  return (
    <ExlyImage
      src={DeleteIcon}
      height={20}
      width={20}
      id="tag"
      alt="delete"
      onClick={() => openDiscountCodesDeleteModal(record)}
      className={`pointer`}
    />
  );
};
