import {
  EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_DELETE_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_MODAL,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_SUCCESS_MODAL,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch, useSelector } from "react-redux";

export const useShowDiscountCodesModal = () => {
  const dispatch = useDispatch();
  const discountCodes = useSelector(
    (state) => state.affiliateMarketing.discountCodes
  );

  const openDiscountCodesModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_MODAL,
      payload: true,
    });
  };

  const closeDiscountCodesModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_MODAL,
      payload: false,
    });
  };

  const openDiscountCodesDeleteModal = (record) => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_DELETE_MODAL,
      payload: true,
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD,
      payload: record,
    });
  };

  const closeDiscountCodesDeleteModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_DELETE_MODAL,
      payload: false,
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD,
      payload: {},
    });
  };

  const showDiscountCodeSuccessModal = ({ value, record = {} }) => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_DISCOUNT_CODES_SUCCESS_MODAL,
      payload: value,
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SELECTED_DISCOUNT_RECORD,
      payload: record,
    });
  };

  return {
    showDiscountCodesModal: discountCodes?.showModal,
    openDiscountCodesModal,
    closeDiscountCodesModal,
    showDiscountCodesDeletModal: discountCodes?.showDeleteModal,
    openDiscountCodesDeleteModal,
    closeDiscountCodesDeleteModal,
    selectedRecord: discountCodes?.selectedRecord,
    showDiscountCodeSuccessModal,
    showSuccessModal: discountCodes?.showSuccessModal,
  };
};
