import React, { useState } from "react";
import { Tabs, Switch } from "@my-scoot/component-library-legacy";
import moduleStyle from "../AffiliateSettingsModal/AffiliateSettingsModal.module.css";
import { Field } from "react-final-form";
import { useFormState } from "react-final-form";
import { AffiliateCommissionForm } from "features/AffiliateMarketing/modules/AffiliateCommissionForm/AffiliateCommissionForm";
import { AffiliateLabel } from "features/AffiliateMarketing/modules/AffiliateLabel/AffiliateLabel";
import {
  AFFILIATE_SETTINGS_TABS,
  form_keys,
} from "../../constants/AffiliateSettings.constants";
import InfoIcon from "@material-ui/icons/InfoOutlined";

export const AffiliateSettingsForm = ({ handleSubmit }) => {
  const { values: currentValues } = useFormState();

  const [selectedTab, setSelectedTab] = useState(
    AFFILIATE_SETTINGS_TABS.affiliate_program_setup.value
  );
  return (
    <form onSubmit={handleSubmit}>
      <div className={`px-3 py-4 px-md-4`}>
        <Tabs
          key="level-one"
          value={selectedTab}
          tabVariant="outlined"
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
          tabs={Object.values(AFFILIATE_SETTINGS_TABS)}
        />

        {selectedTab ===
        AFFILIATE_SETTINGS_TABS.affiliate_program_setup.value ? (
          <div
            className={`d-flex align-items-center justify-content-between mt-4`}
          >
            <AffiliateLabel
              formKeys={form_keys}
              formKey={form_keys.disable_auto_approval.key}
              classes={{ labelClassName: moduleStyle.switch_text }}
            />

            <Field name={form_keys.disable_auto_approval.key} type="checkbox">
              {({ input }) => {
                return (
                  <div>
                    <Switch
                      {...input}
                      checked={input.checked}
                      onChange={(e) => {
                        input.onChange(e.target.checked);
                      }}
                      color="success"
                    />
                  </div>
                );
              }}
            </Field>
          </div>
        ) : (
          <div className={`my-4`}>
            <AffiliateCommissionForm currentValues={currentValues} />

            <div
              className={`d-flex align-items-center ${moduleStyle.disclaimer} p-2 mt-4`}
            >
              <InfoIcon className={`${moduleStyle.disclaimer_icon}`} />
              <div className={`${moduleStyle.disclaimer_text} ml-2`}>
                Any changes made here will be applied to all future affiliates
                that get added
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
