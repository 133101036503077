import {
  EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
  EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
  EXLY_AFFILIATE_MARKETING__SET_SHOW_AFFILIATE_SETTINGS_MODAL,
} from "features/AffiliateMarketing/redux/Affliates.actions";
import { useDispatch, useSelector } from "react-redux";

export const useAffiliateSettingsButton = () => {
  const dispatch = useDispatch();
  const affiliateSettings = useSelector(
    (state) => state.affiliateMarketing.affiliateSettings
  );

  const openAffiliateSettingsModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__FETCH_AFFILIATE_SETTINGS,
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_AFFILIATE_SETTINGS_MODAL,
      payload: true,
    });
  };

  const closeAffiliateSettingsModal = () => {
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_COMMISSION_INITIAL_VALUES,
      payload: {},
    });
    dispatch({
      type: EXLY_AFFILIATE_MARKETING__SET_SHOW_AFFILIATE_SETTINGS_MODAL,
      payload: false,
    });
  };

  return {
    openAffiliateSettingsModal,
    closeAffiliateSettingsModal,
    showAffliateSettingsModal: affiliateSettings.showAffiliateSettingsModal,
    processing: affiliateSettings.processing,
  };
};
