import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import moduleStyle from "./ReviewSelectionModal.module.css";
import { useReviewSelectionModal } from "./utils/useReviewSelectionModal";
import { ReviewSelectionList } from "./modules/ReviewSelectionList/ReviewSelectionList";
import { usePayment } from "features/AffiliateMarketing/utils/usePayment";
import { getSessionStorageItem } from "utils/getterAndSetters";
import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";
import { roundOff } from "utils/Utils";
import ExlyLoader from "ui/modules/ExlyLoader";
import { is_empty } from "utils/validations";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";
import Tooltip from "ui/modules/Tooltip";

export const ReviewSelectionModal = () => {
  const { showPayoutListModal, toggleShowPayoutListModal, payout, processing } =
    useReviewSelectionModal();
  const { initiatePayment } = usePayment();

  return (
    <ExlyModal
      open={showPayoutListModal}
      onClose={() => toggleShowPayoutListModal()}
      modal_props={{
        modalPaperClassName: moduleStyle.reviewSelectionContainer,
      }}
      title={"Payout Commissions"}
      primaryBtnText={
        processing ? (
          "..."
        ) : (
          <div className={`d-flex align-items-center`}>
            <div>{`Pay ${RUPEE_SYMBOL}${roundOff(
              payout?.final_gateway_amount,
              2
            )}`}</div>
            <Tooltip
              text="Includes 3% Payment Gateway charges + GST"
              className={`ml-1`}
              infoIconClassName={`${moduleStyle.infoIcon}`}
            />
          </div>
        )
      }
      showSecondaryBtn={false}
      primaryBtnFullWidth
      primaryBtnProps={{ disabled: payout?.disable_pay_button }}
      onPrimaryBtnClick={() => {
        initiatePayment({
          requestId: getSessionStorageItem(
            SESSION_STORAGE_KEYS.AFFILIATE_PAYOUT_REQUEST_ID
          ),
        });
      }}
    >
      <div className="my-2">
        {processing && is_empty(payout) ? (
          <ExlyLoader />
        ) : (
          <ReviewSelectionList />
        )}
      </div>
    </ExlyModal>
  );
};
