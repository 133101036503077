import React from "react";
import { ActionField } from "../modules/ActionField/ActionField";
import { form_data } from "../../../constants/Affliates.constants";
import EmailField from "ui/Fields/EmailField";
import { WhatsAppPhoneField } from "common/Components/TableFields/TableFields";
import { RUPEE_SYMBOL } from "features/Common/modules/Currency/constants/Currency.constants";
import { DiscountCodeField } from "../modules/DiscountCodeField/DiscountCodeField";
import { NameField } from "../modules/NameField/NameField";
import { roundOff } from "utils/Utils";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

export const emptyStateProps = {
  empty_list_icon: getS3AssetCDNUrl(
    "assets/images/affiliates/affliate_empty.svg"
  ),
  alt: "No affiliates yet.",
  content_title: "No affiliates yet",
  children:
    "Start adding affiliates to oversee their management and transactions",
};

export const getAffilatesColumnConfig = () => [
  {
    field: form_data.title.key,
    headerName: form_data.title.label,
    emptyField: "-",
    sortable: false,
    width: "200px",
    isPrimary: true,
    valueFormatter: (record) => (
      <NameField record={record} source={form_data.title.key} />
    ),
  },
  {
    field: form_data.email.key,
    headerName: form_data.email.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) =>
      record.email ? (
        <EmailField record={record} source={form_data.email.key} />
      ) : (
        "-"
      ),
  },
  {
    field: form_data.phone_number.key,
    headerName: form_data.phone_number.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <WhatsAppPhoneField
        record={record}
        source="phone_number"
        countryCodeKey="country_code"
      />
    ),
  },
  {
    field: form_data.total_bookings.key,
    headerName: form_data.total_bookings.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {record.total_bookings === 0 || record.total_bookings === null
          ? "0"
          : record.total_bookings || "-"}
      </span>
    ),
  },
  {
    field: form_data.total_sales_revenue.key,
    headerName: form_data.total_sales_revenue.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {record.total_sales_revenue === 0 || record.total_sales_revenue === null
          ? "0"
          : `${RUPEE_SYMBOL}${roundOff(record.total_sales_revenue, 2) || "-"}`}
      </span>
    ),
  },
  {
    field: form_data.total_commission.key,
    headerName: form_data.total_commission.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {record.total_commission === 0 || record.total_commission === null
          ? "0"
          : `${RUPEE_SYMBOL}${roundOff(record.total_commission, 2) || "-"}`}
      </span>
    ),
  },
  {
    field: form_data.pending_commission.key,
    headerName: form_data.pending_commission.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => (
      <span>
        {record.pending_commission === 0 || record.pending_commission === null
          ? "0"
          : `${RUPEE_SYMBOL}${roundOff(record.pending_commission, 2) || "-"}`}
      </span>
    ),
  },
  {
    field: form_data.no_of_discount_codes.key,
    headerName: form_data.no_of_discount_codes.label,
    emptyField: "-",
    sortable: false,
    noWrap: true,
    valueFormatter: (record) => <DiscountCodeField record={record} />,
  },
  {
    field: "",
    headerName: "Action",
    valueFormatter: (record) => {
      return <ActionField record={record} />;
    },
    sortable: false,
    noWrap: true,
    hidden: true,
    width: "50px",
  },
];

export const managementTableChipsFormatter = () => (filter, value) => {
  switch (filter) {
    case "status":
      return null;
    case `${form_data.title.key}`:
      return `Name: ${value}`;
    case `${form_data.phone_number.key}`:
      return `Phone: ${value}`;
    case `${form_data.total_bookings.min}`:
      return `Min bookings: ${value}`;
    case `${form_data.total_bookings.max}`:
      return `Max bookings: ${value}`;
    case `${form_data.uuid.key}`:
      return `ID: ${value}`;
    case `${form_data.total_sales_revenue.min}`:
      return `Min sales revenue: ${value}`;
    case `${form_data.total_sales_revenue.max}`:
      return `Max sales revenue: ${value}`;
    case `${form_data.total_commission.min}`:
      return `Min commission: ${value}`;
    case `${form_data.total_commission.max}`:
      return `Max commission: ${value}`;
    case `${form_data.pending_commission.min}`:
      return `Min pending commission: ${value}`;
    case `${form_data.pending_commission.max}`:
      return `Max pending commission: ${value}`;
    case `${form_data.discount_code.key}`:
      return `Discount: ${value}`;
    default:
      return undefined;
  }
};
export const managementFilterConfig = [
  {
    source: form_data.title.key,
    placeholder: "Affiliate Name",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.email.key,
    placeholder: form_data.email.label,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.phone_number.key,
    placeholder: form_data.phone_number.label,
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.total_bookings.min,
    placeholder: "Min bookings",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.total_bookings.max,
    placeholder: "Max bookings",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.total_sales_revenue.min,
    placeholder: "Min sales revenue",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.total_sales_revenue.max,
    placeholder: "Max sales revenue",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.total_commission.min,
    placeholder: "Min total commission",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.total_commission.max,
    placeholder: "Max total commission",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.pending_commission.min,
    placeholder: "Min pending commission",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.pending_commission.max,
    placeholder: "Max pending commission",
    type: "input",
    disableContains: true,
  },
  {
    source: form_data.discount_code.key,
    placeholder: "Discount code",
    type: "input",
    disableContains: true,
  },
];
