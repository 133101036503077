import React from "react";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyTable from "common/Components/ExlyTable";
import moduleStyle from "./TransactionPayout.module.css";
import { affiliates_transactions_tab } from "../AllTransactions/constants/AllTransactions.constants";
import { transaction_tab_config } from "../AllTransactions/constants/transactionsColumn.config";
import { payoutTableChipsFormatter } from "features/AffiliateMarketing/modules/PayoutSummary/constants/payout.config";
import useInvoiceViewer from "features/AffiliateMarketing/utils/useInvoiceViewer";
import {
  affliatTransactionPayoutfilterConfig,
  emptyStateProps,
  getExtendedAffilatesPayoutColumnConfig,
} from "./constants/transactionPayoutColumn.config";
import { AffiliateTransactionHeader } from "features/AffiliateMarketing/modules/AffiliateTransactionHeader/AffiliateTransactionHeader";
import { affiliate_management_back_action } from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import { InvoiceField } from "features/AffiliateMarketing/modules/FormFields/InvoiceField/InvoiceField";
import { useCheckAffiliateAccessible } from "features/AffiliateMarketing/utils/useCheckAffiliateAccessible";
import { PayoutComissionButton } from "../AllTransactions/modules/PayoutComissionButton/PayoutComissionButton";

export const AffliateTransactionsPayout = withComponentLibraryTheme((props) => {
  useCheckAffiliateAccessible();
  const is_desktop = useDesktopMediaQuery();
  const { getInvoiceURL } = useInvoiceViewer();
  const footerProps = {
    customFooter: (
      <div className={`${is_desktop ? "" : "w-100"} mb-1`}>
        <PayoutComissionButton />
      </div>
    ),
  };

  return (
    <>
      <AffiliateTransactionHeader />
      <div className={`px-3 px-md-4`}>
        <ExlyTable
          ra_props={{
            ...props,
          }}
          {...emptyStateProps}
          entityName={"record"}
          tableTitle={
            !is_desktop ? (
              <div className={moduleStyle.headerButtonWrapper}>
                <span>Affiliate Transactions</span>{" "}
              </div>
            ) : (
              ""
            )
          }
          layoutProps={{
            paddingDesktop: "0",
            paddingMobile: "0",
            paddingBottom: "120px",
            noMobileBoxShadow: true,
          }}
          customPrimaryAction={{
            renderCustomAction: (record) => <InvoiceField record={record} />,
          }}
          columnConfig={getExtendedAffilatesPayoutColumnConfig(getInvoiceURL)}
          borderedFields={true}
          fieldsAlignment="space-between"
          drawerFieldsAlignment="space-between"
          drawerFieldsBordered
          recordFooterVariant="secondary"
          tabConfig={transaction_tab_config}
          tableFilters={affliatTransactionPayoutfilterConfig}
          selectedTab={affiliates_transactions_tab.payout_summary.value}
          noExportButton={true}
          tableChipsFormatter={payoutTableChipsFormatter()}
          footerProps={footerProps}
          primaryKey="id"
          hasMobileFooter={true}
          {...affiliate_management_back_action}
        />
      </div>
    </>
  );
});
