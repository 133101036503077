import { ExlyInput } from "common/form";
import React from "react";
import styles from "./bankDetails.module.css";
import { Field, useFormState } from "react-final-form";
import { is_empty } from "features/Common/utils/common.utils";
import { form_keys } from "./BankDetails.constants";

const BankDetails = () => {
  const { values } = useFormState();
  return (
    <div className={styles.contentWrap}>
      <Field
        name={form_keys.account_full_name}
        label="Name"
        size="small"
        component={ExlyInput}
        fullWidth
        placeholder="Enter Affiliate Name"
        wrapperClassName={styles.mb}
        labelClassName="font_500"
        inputElementClassName={styles.disabled}
        disabled
      />
      <div className={styles.input}>
        <Field
          name={form_keys.account_number}
          label="Account Number"
          size="small"
          component={ExlyInput}
          placeholder="Account number"
          wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
          inputClassName={styles.fullWidth}
          inputElementClassName={styles.disabled}
          disabled
        />
        <Field
          component={ExlyInput}
          label="Account Type"
          name={form_keys.account_type}
          placeholder="Account Type"
          size="small"
          wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
          inputClassName={styles.fullWidth}
          inputElementClassName={styles.disabled}
          disabled
        />
      </div>
      <div className={styles.input}>
        <Field
          component={ExlyInput}
          label="Bank Name"
          name={form_keys.bank_name}
          placeholder="Bank Name"
          size="small"
          inputClassName={styles.fullWidth}
          wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
          inputElementClassName={styles.disabled}
          disabled
        />
        <Field
          component={ExlyInput}
          label="IFSC Code"
          name={form_keys.ifsc_code}
          placeholder="IFSC Code"
          size="small"
          inputClassName={styles.fullWidth}
          wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
          inputElementClassName={styles.disabled}
          disabled
        />
      </div>
      <div className={styles.input}>
        <Field
          name={form_keys.pan_number}
          label="PAN Number"
          size="small"
          component={ExlyInput}
          fullWidth
          placeholder="PAN Number"
          inputClassName={styles.fullWidth}
          wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
          inputElementClassName={styles.disabled}
          disabled
        />
        <Field
          component={ExlyInput}
          label="State"
          name={form_keys.billing_state}
          placeholder="State"
          fullWidth
          size="small"
          inputClassName={styles.fullWidth}
          wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
          inputElementClassName={styles.disabled}
          disabled
        />
      </div>
      {!is_empty(values.gst_info) ? (
        <>
          <Field
            name={form_keys.gst_info.gst_name}
            label="Company Name"
            size="small"
            component={ExlyInput}
            fullWidth
            placeholder="Company Name"
            inputClassName={styles.fullWidth}
            wrapperClassName={`${styles.mb}`}
            inputElementClassName={styles.disabled}
            disabled
          />
          <div className={styles.input}>
            <Field
              component={ExlyInput}
              label="Registration Number"
              name={form_keys.gst_info.gst_number}
              placeholder="Registration Number"
              size="small"
              inputClassName={styles.fullWidth}
              wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
              inputElementClassName={styles.disabled}
              disabled
            />
            <Field
              component={ExlyInput}
              label="State"
              name={form_keys.gst_info.gst_company_address}
              placeholder="State"
              size="small"
              inputClassName={styles.fullWidth}
              wrapperClassName={`${styles.mb} ${styles.halfWidth}`}
              inputElementClassName={styles.disabled}
              disabled
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
export default BankDetails;
